import React from 'react';
import PropTypes from 'prop-types';
import Table from '@components/layout/Table';
import MobileTable from '@components/layout/MobileTable';
import moment from 'moment';

class Speeches extends React.Component {
  constructor(props) {
    super(props);
    this.format = {
      day: 'DD/MM/YYYY ',
      time: ' HH:mm A',
      year: 'YYYY',
    };
    this.columns = {
      cosmetic: {
        name: '',
      },
      name: {
        name: window.lang[this.props.language]['title'],
      },
      type: {
        name: window.lang[this.props.language]['type'],
      },
      date: {
        name: window.lang[this.props.language]['date'],
      },
      room: {
        name: window.lang[this.props.language]['room'],
      },
    };
    this.columnsSchedule = {
      cosmetic: {
        name: '',
      },
      name: {
        name: window.lang[this.props.language]['title'],
      },
      speakers: {
        name: window.lang[this.props.language]['speakers'],
      },

      room: {
        name: window.lang[this.props.language]['room'],
      },
      video: {
        name: window.lang[this.props.language]['video'],
      },
      mediaFiles: {
        name: window.lang[this.props.language]['mediaFiles'],
      },
    };
    this.onRender = this.onRender.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getFormattedDay(item) {
    const start = moment(item.startDate.tz);
    const end = moment(item.endDate.tz);

    const sameDay = start.isSame(end, 'days');

    if (sameDay) {
      return (
        <p>
          <span className="date">{moment(start).format(this.format.day)}</span>
          <span className="time">
            {moment(start).format(this.format.time)} -{' '}
            {moment(end).format(this.format.time)}
          </span>
        </p>
      );
    }

    return (
      <div>
        <p>
          <span className="date">{moment(start).format(this.format.day)}</span>
          <span className="time">{moment(start).format(this.format.time)}</span>
        </p>
        <p>
          <span className="date">{moment(end).format(this.format.day)}</span>
          <span className="time">{moment(end).format(this.format.time)}</span>
        </p>
      </div>
    );
  }
  onRender(col, item) {
    switch (col.key) {
      case 'name': {
        return <span style={{ fontWeight: 500 }}>{col.value}</span>;
      }
      case 'type': {
        if (item.isSession) {
          return window.lang[this.props.language]['session'];
        } else {
          return window.lang[this.props.language]['speech'];
        }
      }
      case 'mediaFiles':
        {
          if (item.speechFiles && item.speechFiles.length > 0) {
            return (
              <div className="media-col">
                <ul className="media-list">
                  {item.speechFiles.map((s) => (
                    <li
                      key={`media-${s.id}`}
                      className={`media-file type-${s.extension}`}
                      title={s.fileName}>
                      <a href={s.url} target="_blank" rel="noreferrer">
                        <span className={`icon-pdf`} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            );
          }
          if (item.sessionFiles && item.sessionFiles.length > 0) {
            return (
              <div className="media-col">
                <ul className="media-list">
                  {item.sessionFiles.map((s) => (
                    <li
                      key={`media-${s.id}`}
                      className={`media-file type-${s.extension}`}
                      title={s.fileName}>
                      <a href={s.url} target="_blank" rel="noreferrer">
                        <span className={`icon-pdf`} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            );
          }
        }
        return;

      case 'room': {
        return <div>{item.eventRoomName}</div>;
      }
      case 'speakers': {
        if (item.speakers && item.speakers.length > 0) {
          return (
            <div className="speaker-col">
              <div className="speakers-list">
                {item.speakers.map((speaker) => {
                  const isSpeakerChairperson =
                    speaker.eventSpeakerChairpersonId !== null;
                  const name = `${speaker.firstName} ${speaker.lastName}`;
                  const clickable =
                    speaker.eventSpeakerChairpersonId !==
                    this.props.activeSpeaker;
                  return (
                    <h2
                      style={{
                        color: isSpeakerChairperson ? '#2C5ABA' : 'black',
                        cursor: 'default',
                      }}
                      key={`speaker-${speaker.id}`}
                      className={`speaker${clickable ? ' clickable' : ''}`}
                      onClick={() => {
                        if (!isSpeakerChairperson) return;
                        clickable
                          ? this.props.showSpeaker(
                              speaker.eventSpeakerChairpersonId
                            )
                          : '';
                      }}>
                      {name}
                    </h2>
                  );
                })}
              </div>
            </div>
          );
        } else {
          return <div />;
        }
      }
      case 'chairpersons': {
        if (item.chairpersons.length > 0) {
          return (
            <div className="speaker-col">
              <div className="speakers-list">
                {item.chairpersons.map((cp) => {
                  const isSpeakerChairperson =
                    cp.eventSpeakerChairpersonId !== null;
                  const name = `${cp.firstName} ${cp.lastName}`;
                  const clickable =
                    cp.eventSpeakerChairpersonId !== this.props.activeSpeaker;
                  return (
                    <h2
                      style={{
                        color: isSpeakerChairperson ? '#2C5ABA' : 'black',
                        cursor: 'default',
                      }}
                      key={`speaker-${cp.eventSpeakerChairpersonId}`}
                      className={`speaker${clickable ? ' clickable' : ''}`}
                      onClick={() => {
                        if (!isSpeakerChairperson) return;
                        clickable
                          ? this.props.showSpeaker(cp.eventSpeakerChairpersonId)
                          : '';
                      }}>
                      {name}
                    </h2>
                  );
                })}
              </div>
            </div>
          );
        } else {
          return <div />;
        }
      }
      case 'video': {
        if (
          (item.videoUrl || item.iframeUrl) &&
          this.props.event.publishVideos
        ) {
          return (
            <div className="vod">
              <i
                className="icon-video"
                aria-hidden="true"
                title="Video On Demand"
                onClick={() => {
                  if (this.props.loggedIn) {
                    this.props.showVideo(item.eventSessionId, item.id);
                  } else if (!this.props.loggedIn) {
                    this.props.changeSection('login');
                  }
                }}
              />
            </div>
          );
        } else {
          return (
            <div className="vod" style={{ cursor: 'initial' }}>
              <i
                className="icon-video"
                aria-hidden="true"
                title="Video On Demand"
                style={{ color: '#979797' }}
              />
            </div>
          );
        }
      }
      case 'date': {
        return this.getFormattedDay(item);
      }
    }
  }

  render() {
    const isSession = this.props.sessions;
    const flaggedSessions = [];
    isSession &&
      this.props.sessions.map((session) => {
        session['isSession'] = true;
        flaggedSessions.push(session);
      });
    let allItems = [];
    if (isSession) {
      allItems = [...flaggedSessions, ...allItems];
    }
    if (this.props.speeches) {
      allItems = [...allItems, ...this.props.speeches];
    }
    return (
      <div className="speeches">
        <div>
          <div className="session-list">
            <Table
              id={'list-sessions'}
              columns={isSession ? this.columns : this.columnsSchedule}
              items={allItems}
              onRender={this.onRender}
            />
            {!isSession && !allItems.length && (
              <div className="empty-results border-top">
                <span className="icon-warning-2" />
                <p>
                  {window.lang[this.props.language]['scheduleNotPublished']}
                </p>
              </div>
            )}
          </div>
          <div className="mobile-table">
            <MobileTable
              columns={isSession ? this.columns : this.columnsSchedule}
              items={allItems}
              onRender={this.onRender}
            />
          </div>
        </div>
      </div>
    );
  }
}

Speeches.propTypes = {
  speeches: PropTypes.array,
  title: PropTypes.string,
  activeSpeaker: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  showSpeaker: PropTypes.func.isRequired,
  showVideo: PropTypes.func,
  event: PropTypes.object,
  language: PropTypes.string,
  loggedIn: PropTypes.bool,
  changeSection: PropTypes.func,
  sessions: PropTypes.array,
};

export default Speeches;
