import React from 'react';
import PropTypes from 'prop-types';
import LoginPage from './LoginPage/';
import SignupPage from './SignupPage/';
import QuestionnairePage from './QuestionnairePage/';
import Print from './Print/';
import Register from '../main/Body/Content/Register/Register';
import SplashHeader from './SplashHeader/SplashHeader';

const queryString = require('query-string');

export default class Splash extends React.Component {
  constructor(props) {
    super(props);
    [('getScreenName', 'getComponent')].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );

    this.query = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
  }

  componentDidMount() {
    if (this.props.screen?.length === 0) {
      this.props.changeScreen('login');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.screen?.length === 0) {
      nextProps.changeScreen('login');
    }
  }

  getScreenName() {
    switch (this.props.screen) {
      case 'forgotpassword': {
        return '';
      }
      case 'signup': {
        return '';
      }
      case 'login': {
        return '';
      }
      case 'resetpassword': {
        this.props.getEventInfo();
        return 'Reset Password';
      }
      case 'questionnaire': {
        return 'Questionnaire';
      }
    }
  }

  getComponent() {
    switch (this.props.screen) {
      case 'login': {
        return <LoginPage />;
      }
      case 'forgotpassword': {
        return <LoginPage />;
      }
      case 'forgotPassSuccess': {
        return <LoginPage />;
      }
      case 'resetPassSuccess': {
        return <LoginPage />;
      }
      case 'resetpassword': {
        return <LoginPage />;
      }
      case 'signup': {
        return <SignupPage />;
      }
      case 'questionnaire': {
        return <QuestionnairePage />;
      }
      case 'register': {
        return <Register />;
      }
      case 'print': {
        return <Print />;
      }
    }
  }

  render() {
    const props = this.props;
    if (!props.ready) return null;
    if (props.screen === 'register') {
      return (
        <div>
          <div className="component-register">
            <Register />
          </div>
        </div>
      );
    }
    if (
      props.screen !== 'signup' ||
      (this.query.registration === 'true' &&
        typeof this.query.status === 'undefined') ||
      Object.keys(this.query).length === 0
    ) {
      return (
        <div>
          <div className={`splash ${this.props.screen}-screen`}>
            <div
              className="vertical-center"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <div
                className="splash-container text-left"
                style={{
                  background: 'white',
                  maxWidth:
                    this.props.screen === 'login' ||
                    this.props.screen === 'signup' ||
                    this.props.screen === 'questionnaire' ||
                    this.props.screen === 'forgotpassword' ||
                    this.props.screen === 'forgotPassSuccess' ||
                    this.props.screen === 'resetPassSuccess' ||
                    this.props.screen === 'resetpassword' ||
                    this.props.screen === 'print'
                      ? '100%'
                      : '1370px',
                }}>
                <div
                  className="container"
                  style={{
                    margin: '0px',
                    padding: '0px',
                    minWidth: '100%',
                  }}>
                  <div
                    className="row"
                    style={{
                      minHeight: '100%',
                      margin: '0px',
                      padding: '0px',
                      maxHeight: '100%',
                      height: '100%',
                    }}>
                    <div className={`right-side col-md-6 order-md-2`}>
                      <div className="vertical-center">
                        <div className="presentational">
                          <div className="presentational-inner">
                            {props.policy.banners?.length > 0 && (
                              <div className="event-banner vertical-center">
                                <img
                                  className="blur"
                                  src={props.policy.banners[0].url}
                                  alt={props.policy.eventName}
                                />
                                <img
                                  className="real"
                                  src={props.policy.banners[0].url}
                                  alt={props.policy.eventName}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="left-side col-md-6 order-md-1"
                      style={{
                        overflow: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      {this.props.event.freeAccess !== 1 && (
                        <div className="event-header">
                          <div className="event-logo-wrapper">
                            {!!this.props.policy.clientPanelSettings?.header
                              ?.banner[0]?.url && (
                              <img
                                src={
                                  this.props.policy.clientPanelSettings?.header
                                    ?.banner[0]?.url
                                }
                              />
                            )}
                          </div>
                          <div className="event-title">
                            {this.props.policy.eventName}
                          </div>
                        </div>
                      )}
                      <div
                        className="vertical-center"
                        style={{ maxWidth: '500px', width: '100%' }}>
                        <div className="form-container">
                          <div className="form-inner-wrapper">
                            {this.getComponent()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className={`splash ${this.props.screen}-screen`}>
          <SplashHeader
            policy={this.props.policy}
            language={this.props.language}
          />
          <div
            className="vertical-center"
            style={{
              height: 'calc(100% - 80px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <div className="splash-container text-left">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 left-side order-2 order-md-1">
                    <div className="form-wrapper">
                      <div className="form-container">
                        <div className="form-inner-wrapper">
                          {this.getScreenName() !== '' && (
                            <p className="screen-description">
                              {this.getScreenName()}
                            </p>
                          )}
                          {this.getComponent()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Splash.propTypes = {
  policy: PropTypes.object.isRequired,
  event: PropTypes.object,
  ready: PropTypes.bool,
  screen: PropTypes.string.isRequired,
  getEventInfo: PropTypes.func,
  changeScreen: PropTypes.func,
  language: PropTypes.string,
  user: PropTypes.object,
};
