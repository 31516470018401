const configuration = {
  url: process.env.REACT_APP_BACKEND_URL,
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  googleMapKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  allowedNoCaptcha: 5,
  endpoints: {
    badge: {
      get: {
        method: 'GET',
        url: 'client/badge',
      },
    },
    certificate: {
      get: {
        method: 'GET',
        url: 'client/certificate',
      },
    },
    googleWalletPass: {
      get: {
        method: 'GET',
        url: 'client/google-wallet-pass',
      },
    },
    badgeAppleWallet: {
      get: {
        method: 'GET',
        url: 'client/apple-pass',
      },
    },
    questionnaire: {
      submit: {
        method: 'POST',
        url: 'client/${eventId}/${policyId}/questionnaires/',
      },
    },
    subscriptions: {
      list: {
        method: 'GET',
        url: 'admin/events/${eventId}/subscriptions/',
      },
    },
    videos: {
      get: {
        method: 'GET',
        url: 'admin/events/${eventId}/videos',
      },
    },
    policies: {
      get: {
        method: 'GET',
        url: 'client/${urlAlias}/${policyId}',
      },
      getDefault: {
        method: 'GET',
        url: 'client/${urlAlias}',
      },
      register: {
        method: 'POST',
        url: 'client/${eventId}/${policyId}/register',
      },
      completeRegistration: {
        method: 'POST',
        url: 'client/${eventId}/${policyId}/complete-registration',
      },
      calculateSubscription: {
        method: 'POST',
        url: 'client/${eventId}/${policyId}/calculate-subscription',
      },
      login: {
        method: 'POST',
        url: 'client/${eventId}/${policyId}/login',
      },
    },
    abecommerce: {
      create: {
        method: 'POST',
        url: 'https://alpha.test.modirum.com/vpos/shophandlermpi',
      },
      getData: {
        method: 'POST',
        url: 'admin/events/${eventId}/payments/ecommerce-data',
      },
    },
    watchtime: {
      create: {
        method: 'POST',
        url: 'admin/events/${eventId}/videos-watchtime/',
      },
    },
    payments: {
      paypalTransactionCompleted: {
        method: 'POST',
        url: 'admin/organisations/online-payments/',
      },
      paypalCreateOrder: {
        method: 'POST',
        url: 'admin/events/${eventId}/payments/paypal-create-order',
      },
      receipt: {
        method: 'GET',
        url: 'admin/events/${eventId}/payments/${paymentId}/receipt',
      },
      multipleReceipt: {
        method: 'GET',
        url: 'admin/events/${eventId}/payments/receipt/',
      },
    },
    event: {
      get: {
        method: 'GET',
        url: 'admin/events/${eventId}/${section}',
      },
    },
    sessions: {
      get: {
        method: 'GET',
        url: 'admin/events/${eventId}/sessions',
      },
      getById: {
        method: 'GET',
        url: 'admin/events/${eventId}/sessions/${sessionId}',
      },
      addUser: {
        method: 'POST',
        url: 'admin/events/${eventId}/sessions/${sessionId}/users',
      },
      deleteUser: {
        method: 'DELETE',
        url: 'admin/events/${eventId}/sessions/${sessionId}/users/${userId}',
      },
    },
    validateToken: {
      post: {
        method: 'POST',
        url: 'client/${eventId}/${policyId}/validate-token',
      },
    },
    createToken: {
      post: {
        method: 'POST',
        url: 'client/${eventId}/${policyId}/token',
      },
    },
    verifyEmail: {
      post: {
        method: 'POST',
        url: 'client/${eventId}/${policyId}/verify-email',
      },
    },
    presetEmails: {
      custom: {
        method: 'POST',
        url: 'admin/events/${eventId}/custom-emails/send',
      },
    },
    user: {
      get: {
        method: 'GET',
        url: 'client/user',
      },
      addToAgenda: {
        method: 'PUT',
        url: 'admin/events/${eventId}/policies/${policyId}/users/${userId}',
      },
      notifications: {
        method: 'GET',
        url: 'client/user/notifications',
      },
      notificationsRead: {
        method: 'PUT',
        url: 'client/user/notifications',
      },
      requestResetPassword: {
        method: 'POST',
        url: 'client/${eventId}/${policyId}/request-reset-password',
      },
      resetPassword: {
        method: 'POST',
        url: 'client/${eventId}/${policyId}/reset-password',
      },
      getBadge: {
        method: 'GET',
        url: 'admin/events/${eventId}/badges',
      },
      getCert: {
        method: 'GET',
        url: 'admin/events/${eventId}/certificates',
      },
      update: {
        method: 'PUT',
        url: 'admin/events/${eventId}/policies/${policyId}/users/${userId}',
      },
    },
    speakers: {
      get: {
        method: 'GET',
        url: 'admin/events/${eventId}/speakers-chairpersons',
      },
      getSingle: {
        method: 'GET',
        url: 'admin/events/${eventId}/speakers-chairpersons/${speakerId}',
      },
    },
    hotels: {
      list: {
        method: 'GET',
        url: 'admin/events/${eventId}/hotels',
      },
      availableRooms: {
        method: 'GET',
        url: 'admin/events/${eventId}/hotels/${hotelId}/rooms/',
      },
      createReservation: {
        method: 'POST',
        url: 'admin/events/${eventId}/hotels/${hotelId}/reservations/',
      },
      cancelReservation: {
        method: 'DELETE',
        url: 'admin/events/${eventId}/hotels/${hotelId}/reservations/${reservationId}',
      },
    },
    persons: {
      list: {
        method: 'GET',
        url: 'admin/organisations/${orgId}/persons',
      },
      create: {
        method: 'POST',
        url: 'admin/events/${eventId}/persons',
      },
      update: {
        method: 'PUT',
        url: 'admin/events/${eventId}/persons/${personId}',
      },
    },
    rooms: {
      list: {
        method: 'GET',
        url: 'admin/events/${eventId}/rooms?rpp=-1',
      },
    },
    schema: {
      get: {
        method: 'GET',
        url: 'admin/events/${eventId}/abstracts-schemas/${schemaId}',
      },
    },
    twillio: {
      post: {
        method: 'POST',
        url: 'admin/events/${eventId}/sessions/${sessionId}/twilio-chat/',
      },
    },
    products: {
      list: {
        method: 'GET',
        url: 'client/${eventId}/products',
      },
      create: {
        method: 'POST',
        url: 'client/${eventId}/products',
      },
      delete: {
        method: 'DELETE',
        url: 'client/${eventId}/products/${productId}/prices/${priceId}',
      },
    },
    exhibition: {
      list: {
        method: 'GET',
        url: 'admin/events/${eventId}/exhibitors/',
      },
      get: {
        method: 'GET',
        url: 'admin/events/${eventId}/exhibitors/${exhibitorId}/page',
      },
    },
    storage: {
      presignedURL: {
        method: 'POST',
        url: 'client/events/${eventId}/signedurl',
      },
      saveFile: {
        method: 'POST',
        url: 'client/events/${eventId}/savefile',
      },
    },
    abstracts: {
      list: {
        method: 'GET',
        url: 'admin/events/${eventId}/abstracts',
      },
      mylist: {
        method: 'GET',
        url: 'admin/events/${eventId}/abstracts',
      },
      create: {
        method: 'POST',
        url: 'admin/events/${eventId}/abstracts',
      },
      delete: {
        method: 'DELETE',
        url: 'admin/events/${eventId}/abstracts/${abstractId}',
      },
      update: {
        method: 'PUT',
        url: 'admin/events/${eventId}/abstracts/${abstractId}',
      },
      get: {
        method: 'GET',
        url: 'admin/events/${eventId}/abstracts/${abstractId}',
      },
    },
  },
};

export default configuration;
