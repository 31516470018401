/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getBadge, validateToken, getCertificate } from '@actions';
import PropTypes from 'prop-types'; // Import PropTypes
import generatePDF from 'react-to-pdf'; // Correct import based on official API

import ResetSuccessful from '@dynamic_assets/resetSuccessful.svg.js';
import Loader from '../../layout/Loader';
import { useLocation } from 'react-router-dom';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { PrimaryButton } from '../../layout/Buttons';
import { renderBadgeDynamicContent } from './renderBadge';
import { renderCertificateDynamicContent } from './renderCertificate';
import { renderReceiptDynamicContent } from './renderReceipt';
import jwt_decode from 'jwt-decode';

const paperSizesPX = {
  A4: { width: 794, height: 1123 },
  A5: { width: 559, height: 794 },
  A6: { width: 396, height: 559 },
  receipt: { width: 500, height: 1077 },
};
const SimplePrintPage = ({
  getBadge,
  language,
  validateToken,
  getCertificate,
}) => {
  const pdfRef = useRef();
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState(null);
  const [printing, setPrinting] = useState(false);
  const [verified, setVerified] = useState(null);
  const location = useLocation();
  const [failedTokenError, setFailedTokenError] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('t');
  const printType = queryParams.get('type');

  const printCTA =
    printType === 'badge'
      ? 'printBadge'
      : printType === 'certificate'
      ? 'printCertificate'
      : 'printReceipt';
  const printTitle =
    printType === 'badge'
      ? 'readyBadge'
      : printType === 'certificate'
      ? 'readyCertificate'
      : 'readyReceipt';
  const printMessage =
    printType === 'badge'
      ? 'printBadgeMessage'
      : printType === 'certificate'
      ? 'printCertificateMessage'
      : 'printReceiptMessage';

  // Fetch badge settings from the backend via Redux action when component mounts
  useEffect(() => {
    if (!token) {
      setVerified(0);
      return;
    }
    validateToken(token)
      .then(({ data }) => {
        if (!settings && verified == null) {
          setVerified(1);

          if (printType === 'badge') {
            getBadge(data.eventId, token)
              .then(({ data }) => {
                setSettings(data);
              })
              .catch((error) => {
                console.error('Error fetching badge settings:', error);
              })
              .finally(() => {
                setLoading(false);
              }); // Dispatch the action to fetch badge settings
          }

          if (printType === 'certificate') {
            getCertificate(data.eventId, token)
              .then(({ data }) => {
                setSettings(data);
              })
              .catch((error) => {
                console.error('Error fetching badge settings:', error);
              })
              .finally(() => {
                setLoading(false);
              }); // Dispatch the action to fetch badge settings
          }
          if (printType === 'receipt') {
            const data = jwt_decode(token);
            data.orientation = 'portrait';
            data.paperSize = 'receipt';

            setSettings(data);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setVerified(0);
        setLoading(false);
        setFailedTokenError(error.toString());
      });
  }, [getBadge, settings]);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (verified === 0) {
    return (
      <div className="invalid-token">
        <h3>Expired or invalid token</h3>
        <p>Please contact the administration for more information</p>
        <div className="warning-message">
          <div className="warning-icon-wrapper">
            <ReportProblemOutlinedIcon className="warning-icon" />
          </div>
          <div className="warning-message-text">{failedTokenError}</div>
        </div>
        <PrimaryButton
          onClick={() =>
            window.location.replace(window.location.href.split('?')[0])
          }>
          OK
        </PrimaryButton>
      </div>
    );
  }

  const options = {
    filename: `badge_${settings.user.id || 'default'}.pdf`,
    method: 'open', // Display PDF in the browser
    page: {
      margin: 0, // Small margin for the PDF
      format: printType !== 'receipt' ? settings.paperSize : 'A6', // A6 in px
      orientation: settings.orientation, // Landscape orientation
    },
    overrides: {
      pdf: {
        compress: true, // Compress the PDF to reduce size
      },
      canvas: {
        useCORS: true, // Ensure cross-origin images load correctly
        width:
          settings.orientation === 'landscape'
            ? paperSizesPX[settings.paperSize].height
            : paperSizesPX[settings.paperSize].width,
        height:
          settings.orientation === 'landscape'
            ? paperSizesPX[settings.paperSize].width
            : paperSizesPX[settings.paperSize].height,
        orientation: settings.orientation,
      },
    },
  };
  console.info('options', JSON.stringify(options));
  return (
    <>
      <div className="toolbar">
        <div className="login-helper-icon">
          <ResetSuccessful
            color1="var(--success-100)"
            color2="var(--success-400)"
            color3="var(--success-50)"
          />
        </div>
        <div className="helper-title">{window.lang[language][printTitle]}</div>
        <div className="helper-message">
          {window.lang[language][printMessage]}
        </div>
        <button
          type="button"
          disabled={printing}
          className={`btn login btn-primary`}
          onClick={() => {
            setPrinting(true);
            generatePDF(pdfRef, options).finally(() => {
              setPrinting(false);
            });
          }}>
          {window.lang[language][printCTA]}
        </button>
      </div>
      <div className={'pdf-print'}>
        {/* Content to be converted to PDF */}
        {printType === 'badge' && (
          <div ref={pdfRef}>
            {renderBadgeDynamicContent(settings, paperSizesPX)}
          </div>
        )}
        {printType === 'certificate' && (
          <div ref={pdfRef}>
            {renderCertificateDynamicContent(settings, paperSizesPX)}
          </div>
        )}
        {printType === 'receipt' && (
          <div ref={pdfRef}>
            {renderReceiptDynamicContent(settings, paperSizesPX)}
          </div>
        )}
      </div>
    </>
  );
};

SimplePrintPage.propTypes = {
  getBadge: PropTypes.func.isRequired, // getBadge should be a function
  getCertificate: PropTypes.func.isRequired, // getCertificate should be a function
  language: PropTypes.string.isRequired, // Language should be a string
  validateToken: PropTypes.func,
};

// Map state to props to get settings and loading state from Redux store
const mapStateToProps = (state) => ({
  language: state.api.language.selected,
});

// Map dispatch to props to get the action to fetch badge settings
const mapDispatchToProps = (dispatch) => ({
  getBadge: (eventId, token) => dispatch(getBadge(eventId, token)), // Action to fetch badge settings
  getCertificate: (eventId, token) => dispatch(getCertificate(eventId, token)), // Action to fetch badge settings
  validateToken: (token) => dispatch(validateToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SimplePrintPage);
