import React from 'react';
import PropTypes from 'prop-types';
import Payments from '../Payments';
import AccountInfo from './AccountInfo';
import History from './History';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getHistory } from '@helpers/history';
export default class Account extends React.Component {
  constructor(props) {
    super(props);
    this.checkIfOwe = this.checkIfOwe.bind(this);
    this.changeSubMenu = this.changeSubMenu.bind(this);
    this.history = getHistory();
    this.columns = {
      type: {
        name: window.lang[this.props.language]['type'],
      },
      way: {
        name: window.lang[this.props.language]['way'],
      },
      paidAmount: {
        name: window.lang[this.props.language]['amount'],
      },
      status: {
        name: window.lang[this.props.language]['status'],
      },
    };
    this.submenu = [
      {
        key: 'account',
        label: window.lang[this.props.language]['account'],
      },
      {
        key: 'pending',
        label: window.lang[this.props.language]['pendingPayments'],
      },
      {
        key: 'history',
        label: window.lang[this.props.language]['paymentsHistory'],
      },
    ];
    this.state = {
      fields: {},
      valid: false,
      disabled: true,
      userOwes: undefined,
      active: 'account',
      payments: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    const payments = [];
    const { accomodation, subscription, products } = this.props.user;

    const accomodationPayment = accomodation?.payments;
    const accomodationSponsorship = accomodation?.sponsorships;
    const subscriptionPayment = subscription?.payment;
    const subscriptionSponsorship = subscription?.sponsorship;

    products &&
      products.map((product) => {
        let productName = product.name.split(' ');
        productName.splice(0, 1);
        productName = productName.join(' ');
        if (product.dueAmount > 0) {
          payments.push({
            referenceName: 'productpurchase_' + product.id,
            id: product.id,
            transactionId: 'N/A',
            type: productName,
            checkAvailabilityName: 'product_' + product.eventProductId,
            way: 'N/A',
            paidAmount: `${product.dueAmount} ${this.props.currency}`,
            status: 'Pending',
          });
        }
      });
    // check accomodation
    if (accomodation && Object.keys(accomodation).length > 0) {
      //case there is accomodation

      //case accomodation is sponsored
      if (Object.keys(accomodationSponsorship).length > 0) {
        //case when accomodation is sponsored
        payments.unshift({
          referenceName: 'accomodation',
          id: accomodation.sponsorships.id,
          transactionId: 'N/A',
          type: 'Accommodation',
          way: `Sponsord By ${accomodation.sponsorships.orgSponsorName}`,
          paidAmount: `${accomodation.price} ${this.props.currency}`,
          status: 'Completed',
        });
      } else if (Object.keys(accomodationPayment).length > 0) {
        // case payment is partially paid
        if (accomodationPayment.dueAmount > 0) {
          payments.unshift({
            referenceName: 'accomodation',
            id: 'N/A',
            way: 'N/A',
            type: 'Accommodation',
            transactionId: 'N/A',
            paidAmount: `${accomodationPayment.dueAmount} ${this.props.currency}`,
            status: 'Pending',
          });
        }
      } else if (accomodation.price > 0) {
        // case payment is not paid at all and over zero

        payments.unshift({
          referenceName: 'accomodation',
          id: 'N/A',
          way: 'N/A',
          type: 'Accommodation',
          transactionId: 'N/A',
          paidAmount: `${accomodation.price} ${this.props.currency}`,
          status: 'Pending',
        });
      }
    }

    // check registration
    if (
      Object.keys(subscription).length > 0 &&
      (this.props.user.info.sponsored == 0 || !this.props.user.info.sponsored)
    ) {
      //case when subscription exists

      //case subscription is sponsored
      if (Object.keys(subscriptionSponsorship).length > 0) {
        // case when subscription is sponsored
        payments.unshift({
          referenceName: 'registration',
          id: subscription.sponsorship.id,
          transactionId: 'N/A',
          type: 'Registration',
          way: `Sponsord By ${subscription.sponsorship.orgSponsorName}`,
          paidAmount: `${subscription.eventSubscriptionPrice} ${this.props.currency}`,
          status: 'Completed',
        });
      } else if (Object.keys(subscriptionPayment).length > 0) {
        // case when subscription is partially paid
        if (
          subscriptionPayment.dueAmount > 0 ||
          this.props.user.subscriptionDueAmount > 0
        ) {
          payments.unshift({
            referenceName: 'registration',
            id: 'N/A',
            way: 'N/A',
            type: 'Registration',
            transactionId: 'N/A',
            paidAmount: `${Math.max(
              subscriptionPayment.dueAmount,
              this.props.user.subscriptionDueAmount
            )} ${this.props.currency}`,
            status: 'Pending',
          });
        }
      } else if (subscription.eventSubscriptionPrice > 0) {
        // case when subscription not paid at all
        payments.unshift({
          referenceName: 'registration',
          id: 'N/A',
          way: 'N/A',
          type: 'Registration',
          transactionId: 'N/A',
          paidAmount: `${subscription.eventSubscriptionPrice} ${this.props.currency}`,
          status: 'Pending',
        });
      }
    }
    this.setState({ payments });
    if (this.props.pageProps === 'billing') {
      this.changeSubMenu('pending');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pageProps === 'billing') {
      this.changeSubMenu('pending');
      return;
    }
    this.changeSubMenu('account');
  }

  changeSubMenu(active) {
    if (active === 'account') {
      this.history.push(`/account`);
    } else if (active === 'pending') {
      this.history.push(`/billing`);
    }

    this.setState({ active });
  }
  checkIfOwe(value) {
    this.setState({ userOwes: value });
  }
  getPaymentsLength() {
    return this.state.payments.filter((p) => p.status === 'Pending').length;
  }
  render() {
    const { user } = this.props;
    const paymentsLength = this.getPaymentsLength();

    const { payments } = user;
    const primaryColor =
      this.props.event &&
      this.props.event.clientPanelSettings &&
      this.props.event.clientPanelSettings.general.colors.primary;

    return (
      <React.Fragment>
        <div className="top-menu mobile-top-menu">
          <Select
            id="view-select"
            variant="outlined"
            value={this.state.active}
            onChange={(e) => this.changeSubMenu(e.target.value)}>
            {this.submenu.map((submenu) => (
              <MenuItem
                key={`${submenu.key}-menu-selection`}
                value={submenu.key}>
                {submenu.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <ul className="top-menu desktop-top-menu">
          {this.submenu.map((s) => {
            return (
              <li
                key={`submenu-${s.key}`}
                className={`submenu-item ${
                  this.state.active === s.key ? 'active' : ''
                }`}>
                <button
                  type="button"
                  className="btn"
                  style={{
                    color: this.state.active === s.key && primaryColor,
                  }}
                  onClick={() => this.changeSubMenu(s.key)}>
                  {s.label}
                </button>
                {s.key === 'pending' && paymentsLength > 0 ? (
                  <span className="cart-number">{paymentsLength}</span>
                ) : null}
              </li>
            );
          })}
        </ul>
        <div className="component-billing">
          {this.state.userOwes === true && (
            <div className="payment-info-box">
              <i className="icon-warning-2" />
              {window.lang[this.props.language]['duesMessage']}
            </div>
          )}
        </div>
        {this.state.active === 'account' && (
          <AccountInfo
            user={user}
            updateUser={this.props.updateUser}
            policy={this.props.policy}
            language={this.props.language}
            requestResetPassword={this.props.requestResetPassword}
            primaryColor={primaryColor}
            event={this.props.event}
            getCert={this.props.getCert}
          />
        )}
        {this.state.active === 'pending' && (
          <div className="account-container" style={{ display: 'inherit' }}>
            <Payments checkIfOwe={this.checkIfOwe} calledFromAccount={true} />
          </div>
        )}
        {this.state.active === 'history' && (
          <div className="account-container">
            <History
              payments={payments}
              language={this.props.language}
              currency={this.props.currency}
              getReceipt={this.props.getReceipt}
              user={user}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

Account.propTypes = {
  policy: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  user: PropTypes.object,
  currency: PropTypes.string,
  updateUser: PropTypes.func,
  event: PropTypes.object,
  requestResetPassword: PropTypes.func,
  getReceipt: PropTypes.func,
  getBadge: PropTypes.func,
  getCert: PropTypes.func,
  pageProps: PropTypes.string,
};
