import React from 'react';

export const renderReceiptDynamicContent = (receiptData, paperSizesPX) => {
  // Determine paper dimensions based on orientation and paper size.
  const isLandscape = receiptData.orientation === 'landscape';
  const paperSize = paperSizesPX[receiptData.paperSize]; // Default to A4 if not defined

  const pageWidth = isLandscape ? paperSize.height : paperSize.width;
  const pageHeight = isLandscape ? paperSize.width : paperSize.height;

  if (!receiptData) {
    return <div>No receipt data available</div>;
  }

  // Destructure all needed fields from receiptData, including eventName and user info.
  const {
    eventName,
    idAndName,
    orderNumber,
    orderDescription,
    merchantName,
    merchantOnlineAddress,
    ecommerceService,
    merchantServices,
    amount,
    transactionDate,
    currency,
    authorizationCode,
    // eventBannerURL,
    // organisationLogo,
    eventColor,
  } = receiptData;

  // Build the paymentData object using the labels provided.
  const paymentData = {
    id: {
      lang_gr_label: 'ID - ΟΝΟΜΑΤΕΠΩΝΥΜΟ ΑΓΟΡΑΣΤΗ',
      lang_en_label: 'ID - PURCHASER NAME',
      value: idAndName || '-',
    },
    transaction_num: {
      lang_gr_label: 'ΑΡΙΘΜΟΣ ΣΥΝΑΛΛΑΓΗΣ',
      lang_en_label: 'ORDER I.D.',
      value: orderNumber || '',
    },
    transaction_summary: {
      lang_gr_label: 'ΠΕΡΙΓΡΑΦΗ ΣΥΝΑΛΛΑΓΗΣ',
      lang_en_label: 'ORDER DESCRIPTION',
      value: orderDescription || '',
    },
    merchant_name: {
      lang_gr_label: 'ΕΠΩΝΥΜΙΑ ΕΠΙΧΕΙΡΗΣΗΣ',
      lang_en_label: 'MERCHANT NAME',
      value: merchantName || '',
    },
    merchant_email: {
      lang_gr_label: 'ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ ΕΠΙΧΕΙΡΗΣΗΣ',
      lang_en_label: 'MERCHANT ON-LINE ADDRESS',
      value: merchantOnlineAddress || '-',
    },
    merchandise_description: {
      lang_gr_label: 'ΠΕΡΙΓΡΑΦΗ ΕΜΠΟΡΕΥΜΑΤΩΝ – ΥΠΗΡΕΣΙΩΝ',
      lang_en_label: 'DESCRIPTION OF MERCHANDISE - SERVICES',
      value: merchantServices || '',
    },
    collaborator: {
      lang_gr_label: 'ΥΠΗΡΕΣΙΑ ΗΛΕΚΤΡΟΝΙΚΟΥ ΕΜΠΟΡΙΟΥ',
      lang_en_label: 'E-COMMERCE SERVICE',
      value: ecommerceService || '',
    },
    transaction_ammount: {
      lang_gr_label: 'ΠΟΣΟ ΑΓΟΡΑΣ',
      lang_en_label: 'TRANSACTION AMMOUNT',
      value: amount ? `${amount} ${currency}` : '-',
    },
    transaction_date: {
      lang_gr_label: 'ΗΜΕΡΟΜΗΝΙΑ ΣΥΝΑΛΛΑΓΗΣ',
      lang_en_label: 'TRANSACTION DATE',
      value: transactionDate || '',
    },
    currency: {
      lang_gr_label: 'ΝΟΜΙΣΜΑ',
      lang_en_label: 'CURRENCY',
      value: currency || '',
    },
    authorization_code: {
      lang_gr_label: 'ΑΡΙΘΜΟΣ ΕΓΚΡΙΣΗΣ',
      lang_en_label: 'AUTHORIZATION CODE',
      value: authorizationCode || '',
    },
  };

  // Inline styles (adjust as needed)
  const receiptWrapperStyle = {
    fontFamily: "'Lato', sans-serif",
    position: 'relative',
    width: pageWidth,
    height: pageHeight,
  };

  const headerWrapperStyle = {
    background: eventColor,
    padding: '10px 20px',
    height: '96px',
    display: 'flex',
    alignItems: 'center',
  };

  const leftSideLogoStyle = {
    flex: 1,
    display: 'flex',
    // columnGap: '24px',
    alignItems: 'center',
  };

  const logoContainerStyle = {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  };

  // const logoStyle = {
  //   maxHeight: '96px',
  //   maxWidth: '96px',
  //   objectFit: 'contain',
  // };

  const eventTitleStyle = {
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '38px',
    // For line clamping, we use -webkit-box
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'normal',
    overflowWrap: 'anywhere',
    color: 'white',
  };

  const rightSideTitleStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '28px',
    wordBreak: 'normal',
    overflowWrap: 'anywhere',
  };

  const contentWrapperStyle = {
    margin: '40px 0px',
    height: '100%',
    width: '100%',
  };

  const tableRowStyle = {
    minHeight: '60px',
    borderBottom: '1px solid #D4D4D7',
    display: 'flex',
    padding: '10px 20px',
  };

  // Added wordBreak to ensure long words are broken
  const labelInfoStyle = {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    wordBreak: 'normal',
    overflowWrap: 'anywhere',
  };

  const infoFieldStyle = {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#3F3F49',
    wordBreak: 'normal',
    overflowWrap: 'anywhere',
  };

  const receiptEndWrapperStyle = {
    padding: '16px',
    width: '100%',
    height: '120px',
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  };

  const printDateStyle = {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#3F3F49',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'normal',
    overflowWrap: 'anywhere',
  };

  const endLogosStyle = {
    height: '72px',
    display: 'flex',
    justifyContent: 'flex-end',
    // columnGap: '40px',
  };

  // const endLogoStyle = {
  //   height: '72px',
  //   objectFit: 'contain',
  // };

  return (
    <div className="receipt-wrapper" style={receiptWrapperStyle}>
      {/* Header */}
      <div className="header-wrapper" style={headerWrapperStyle}>
        <div className="left-side-logo" style={leftSideLogoStyle}>
          <div className="logo-container" style={logoContainerStyle}>
            {/* <img
              className="logo"
              src={eventBannerURL}
              alt="Event Banner"
              style={logoStyle}
            /> */}
          </div>
          <div>
            <div
              className="event-name"
              style={{ ...eventTitleStyle, fontSize: '16px' }}>
              {eventName}
            </div>
            {/* <div
              className="order-description"
              style={{ ...eventTitleStyle, fontSize: '20px' }}>
              {orderDescription}
            </div> */}
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="content-wrapper" style={contentWrapperStyle}>
        {Object.entries(paymentData).map(([key, row]) => (
          <div key={key} style={tableRowStyle}>
            <div style={labelInfoStyle}>
              <div>{row.lang_gr_label}</div>
              <div
                style={{ color: '#777', fontSize: '12px', marginTop: '2px' }}>
                {row.lang_en_label}
              </div>
            </div>
            <div style={infoFieldStyle}>{row.value || '-'}</div>
          </div>
        ))}

        {/* Footer */}
        <div className="receipt-end-wrapper" style={receiptEndWrapperStyle}>
          <div className="print-date" style={printDateStyle}>
            Ημερομηνία Εκτύπωσης: {transactionDate || '-'}
          </div>
          <div className="end-logos" style={endLogosStyle}>
            {/* <img
              className="end-logo"
              src={organisationLogo}
              alt="Organization Logo"
              style={endLogoStyle}
            />
            <img
              className="end-logo"
              src={eventBannerURL}
              alt="Event Banner"
              style={endLogoStyle}
            /> */}
          </div>
          <div className="right-side-title" style={rightSideTitleStyle}>
            Αποδεικτικό Συναλλαγής
          </div>
        </div>
      </div>
    </div>
  );
};
