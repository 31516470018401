import React from 'react';
import { capitalizeFirstLetter } from '@helpers';
import PropTypes from 'prop-types';

import GeneralInfo from './Content/GeneralInfo/';
import Sponsors from './Content/Sponsors/';
import Ebag from './Content/Ebag/';
import Register from './Content/Register/Register';
import Exhibition from './Content/Exhibition/Exhibition';
import ExhibitorPage from './Content/Exhibition/ExhibitorPage';

import Schedule from './Content/Schedule/';
import SessionInner from './Content/Schedule/SessionInner/';
import SpeakerInner from './Content/Speakers/SpeakerInner/';
import HotelInner from './Content/HotelManagement/HotelInner/';

import Speakers from './Content/Speakers/';
import Account from './Content/Account';

import HotelManagement from './Content/HotelManagement/';
import AbstractManagement from './Content/Abstracts/Abstracts';
import Billing from './Content/Account/';
import AlphaBankEC from './Content/Payments/AlphaBankEC';
import SingleAbstract from './Content/Abstracts/partials/SingleAbstract';
import PreviewAbstractCard from './Content/Abstracts/partials/PreviewAbstractCard';
import SingleVideo from './Content/Videos/partials/SingleVideo';
import Products from './Content/Products/Products';
import Videos from './Content/Videos/Videos';

import Splash from '../../splash';

import KMBLoader from '@components/layout/KMBLoader';

import FooterInfo from './Content/GeneralInfo/Partials/FooterInfo';

export default class Body extends React.Component {
  constructor(props) {
    super(props);
    this.components = {
      GeneralInfo,
      Sponsors,
      Ebag,
      Schedule,
      Speakers,
      Account,
      HotelManagement,
      AbstractManagement,
      SingleAbstract,
      SingleVideo,
      Billing,
      Videos,
      Register,
      Products,
      Exhibition,
    };
    ['getCurrentComponent'].forEach((fn) => (this[fn] = this[fn].bind(this)));
  }

  getCurrentComponent() {
    if (this.props.showingLogin.active) {
      return <Splash />;
    }
    if (this.props.showingAbstract.active) {
      return <SingleAbstract />;
    }
    if (this.props.showingAbstractPreview.active) {
      return <PreviewAbstractCard />;
    }
    if (this.props.showingHotel.active) {
      return <HotelInner />;
    }

    if (this.props.showingSpeeches.active) {
      return <SessionInner />;
    }
    if (this.props.showingSpeaker.active) {
      return <SpeakerInner />;
    }
    if (this.props.showingExhibitor.active) {
      return <ExhibitorPage />;
    }
    if (this.props.showingPayment.active) {
      return <AlphaBankEC />;
    }

    if (this.props.showingVideo.active && this.props.showingVideo.sessionId) {
      return <SingleVideo />;
    }

    const { active } = this.props;
    const compName = capitalizeFirstLetter(active);
    const Component = this.components[compName] || null;
    return Component ? <Component name={compName} /> : null;
  }

  render() {
    const { active } = this.props;
    const {
      showingSpeaker,
      showingHotel,
      showingSpeeches,
      showingPayment,
      showingAbstract,
      showingAbstractPreview,
      showingVideo,
      showingExhibitor,
      showingProduct,
    } = this.props;
    const name = capitalizeFirstLetter(active);
    const subpage =
      showingSpeeches.active ||
      showingSpeaker.active ||
      showingHotel.active ||
      showingAbstract.active ||
      showingAbstractPreview.active ||
      showingVideo.active ||
      showingExhibitor.active ||
      showingProduct.active ||
      showingPayment.active;

    return (
      <React.Fragment>
        <main className="main-area">
          <div
            className={`main-component container component-${name.toLowerCase()}${
              subpage ? ' component-subpage' : ''
            }`}>
            <div className={`component-inner`}>
              {this.props.loading && (
                <div className="loader-wrapper">
                  <KMBLoader rows={26} />{' '}
                </div>
              )}
              <div
                className={`${
                  this.props.loading ? 'd-none' : 'component-inner-child'
                }`}>
                {this.getCurrentComponent()}
              </div>
            </div>
          </div>
        </main>
        {this.props.active !== 'login' && (
          <footer>
            <FooterInfo
              event={this.props.event}
              active={this.props.active}
              language={this.props.language}
            />
          </footer>
        )}
      </React.Fragment>
    );
  }
}

Body.propTypes = {
  active: PropTypes.string.isRequired,
  menu: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  showingSpeeches: PropTypes.object.isRequired,
  showingSpeaker: PropTypes.object.isRequired,
  showingHotel: PropTypes.object.isRequired,
  showingAbstract: PropTypes.object.isRequired,
  showingAbstractPreview: PropTypes.object.isRequired,
  showingVideo: PropTypes.object.isRequired,
  showingPayment: PropTypes.object.isRequired,
  showingExhibitor: PropTypes.object.isRequired,
  showingProduct: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  showingLogin: PropTypes.object,
  language: PropTypes.string,
};
