import React from 'react';
import { connect } from 'react-redux';
import '../scss/style.scss';
import Loader from '../components/layout/Loader';
import PropTypes from 'prop-types';
import Splash from '../components/splash/';
import Main from '../components/main/';
import Notifications from '../components/layout/Notifications';
import Screen404 from '@components/layout/Screen404';
import configuration from '@config/conf';
import socketsHelper from '../helpers/sockets-helper';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { setPrimaryColor } from '@config/colorPalette';
import Logoutter from './layout/Logoutter';
import jwt_decode from 'jwt-decode';
import { buildTokenName } from '../helpers';
import queryString from 'query-string';

import { getHistory, getBasename } from '@helpers/history';
import {
  changeSection,
  selectHotel,
  showSpeaker,
  showSponsor,
  showVideo,
  showSpeeches,
  createNewAbstract,
  showAbstractPreview,
  showAbstract,
  showLogin,
  showRegister,
  showExhibitor,
  noteLastProhibitedURL,
  promptLogout,
} from '@actions';

const theme = (color) => {
  setPrimaryColor(color);
  return createTheme({
    palette: {
      primary: {
        main: color,
      },
    },
  });
};

class App extends React.Component {
  constructor(props) {
    super(props);
    ['getSectionByRoute'].forEach((fn) => (this[fn] = this[fn].bind(this)));
    this.assignQueryParamsFromUrlToThis();
  }

  assignQueryParamsFromUrlToThis() {
    this.query = queryString.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
  }
  UNSAFE_componentWillMount() {
    let { pathname } = this.props.location;
    const { getSectionByRoute } = this;
    let subpage = false;
    pathname = pathname
      .split('?')[0]
      .split('/')
      .filter((n) => n !== '');

    if (!this.props.loading) {
      if (
        this.props.user.data.hasOwnProperty('rn') &&
        this.props.policy.data.freeAccess &&
        pathname.length !== 0 &&
        pathname[0] !== 'schedule' &&
        pathname[0] !== 'speakers'
      ) {
        const history = getHistory();
        pathname = [''];
        history.push(`/`);
      }
    }
    if (pathname.length === 2) {
      // subpage
      subpage = pathname[1];
      pathname = pathname[0];
    } else if (
      pathname.length === 3 &&
      (pathname[0] === 'abstracts' || pathname[0] === 'videos') &&
      (pathname[1] === 'view' || pathname[1] === 'preview')
    ) {
      subpage = pathname[2];
      delete pathname[2];

      pathname = pathname.join('/');
    } else {
      pathname = pathname.join('/');
    }
    const route = pathname.replace(/\//g, '');
    if (route !== '') {
      const section = getSectionByRoute(route);
      if (section) {
        if (subpage) {
          switch (section) {
            case 'hotelManagement': {
              this.props.selectHotel(subpage);
              break;
            }
            case 'abstractManagement': {
              this.props.createNewAbstract();
              break;
            }
            case 'viewAbstract': {
              this.props.showAbstract(subpage);
              break;
            }
            case 'previewAbstract': {
              this.props.showAbstractPreview(subpage);
              break;
            }
            case 'schedule': {
              this.props.showSpeeches(subpage);
              break;
            }
            case 'speakers': {
              this.props.showSpeaker(parseInt(subpage));
              break;
            }
            case 'sponsors': {
              this.props.showSponsor(parseInt(subpage));
              break;
            }
            case 'exhibition': {
              this.props.showExhibitor(parseInt(subpage));
              break;
            }

            case 'viewVideo': {
              const values = subpage.split('-');
              this.props.showVideo(...values);
              break;
            }
            case 'login': {
              this.props.showLogin();
              break;
            }
            case 'register': {
              this.props.showRegister();
              break;
            }
          }
        } else {
          if (section === 'billing') {
            this.props.changeSection('billing', false, 'billing');
            return;
          }
          this.props.changeSection(section);
        }
      } else {
        this.props.changeSection('generalInfo');
      }
    }
  }

  componentDidMount() {
    const baseName = getBasename();
    const { getSectionByRoute } = this;
    window.addEventListener(
      'popstate',
      () => {
        const route = window.location.pathname
          .replace(baseName, '')
          .replace(/\//g, '');
        const section = getSectionByRoute(route);
        if (section) this.props.changeSection(section, true);
      },
      false
    );
  }

  initSockets() {
    if (
      !this.props?.user?.data?.id ||
      socketsHelper.isConnected() ||
      socketsHelper.isConnecting()
    )
      return;
    const tokenName = buildTokenName(
      this.props.event.data?.id,
      this.props.policy.data?.id
    );
    const accessToken = localStorage.getItem(tokenName);
    socketsHelper
      .init(
        configuration.url,
        `client/${this.props.user.data.id}/`,
        accessToken
      )
      .then((socket) => {
        socket.on('promptLogout', (packet) => {
          if (accessToken) {
            const decodedToken = jwt_decode(accessToken);

            const sessionEquality =
              packet.data.sessionToProtect.id === decodedToken.id &&
              packet.data.sessionToProtect.dc === decodedToken.dc;

            if (
              this.props.loggedIn &&
              !this.props.shouldPromptLogout &&
              packet.data.logout &&
              !sessionEquality
            ) {
              this.props.promptLogout(true);
            }
          }
        });
      })
      .catch((error) => {
        console.error(`Error: ${error}`);
      });
  }

  getSectionByRoute(route) {
    const { items } = this.props;
    let section = false;
    Object.entries(items).map(([k, v]) => {
      if (v.route === route) {
        section = k;
      }
    });
    return section;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.assignQueryParamsFromUrlToThis();

    if (
      this.props.loggedIn &&
      nextProps.loggedIn &&
      this.props.policy.data.concurrentConnectionsLimit === 0
    ) {
      this.initSockets();
    }

    let { pathname } = nextProps.location;
    pathname = pathname
      .split('?')[0]
      .split('/')
      .filter((n) => n !== '');
    let allowed = true;
    document.title = this.props.policy.data.eventName || document.title;
    if (!this.props.loading) {
      if (
        (this.props.user.data.hasOwnProperty('rn') &&
          this.props.policy.data.freeAccess &&
          pathname.length !== 0 &&
          pathname[0] !== 'schedule' &&
          pathname[0] !== 'speakers' &&
          pathname[0] !== 'login' &&
          pathname[0] !== 'sponsors' &&
          pathname[0] !== 'register' &&
          pathname[0] !== 'products' &&
          pathname[0] !== 'abstracts' &&
          pathname[0] !== 'exhibition' &&
          pathname[0] !== 'ebag') ||
        (this.props.user.data.hasOwnProperty('rn') &&
          this.props.policy.data.freeAccess &&
          pathname.length !== 0 &&
          pathname[0] === 'abstracts' &&
          pathname[1] === 'create')
      ) {
        allowed = false;
      }
    }
    const history = getHistory();

    if (nextProps.route !== this.props.route) {
      if (!nextProps.popstate) {
        if (allowed && !this.props.loading) {
          if (nextProps.route === 'billing') {
            const query = (nextProps.location || {}).search || '';
            history.push(`/${nextProps.route}${query}`);
          } else {
            history.push(`/${nextProps.route}`);
          }
        }
      }
    }

    if (!allowed) {
      if (!window.location.href.includes('login'))
        this.props.noteLastProhibitedURL(window.location.href);
      this.props.changeSection('login');
      history.push(`/login`);
    }
  }

  render() {
    const { mobileToggled, policy, user, event } = this.props;
    const noLoginNoFreeAccess = !this.props.loggedIn && !policy.data.freeAccess;
    const guestLoggedInNoFreeAccess =
      this.props.loggedIn &&
      !policy.data.freeAccess &&
      user.data.hasOwnProperty('rn');
    const color =
      event?.data?.clientPanelSettings?.general?.colors?.primary ??
      policy?.data?.clientPanelSettings?.general?.colors?.primary;
    const showLogin = Boolean(
      noLoginNoFreeAccess ||
        guestLoggedInNoFreeAccess ||
        this.query.t ||
        this.query.registration
    );
    return (
      <ThemeProvider theme={theme(color ? color : '#368cfe')}>
        <div className={`app-wrapper ${mobileToggled ? 'mobile-toggled' : ''}`}>
          {this.props.init.path &&
            (this.props.loading ||
              (showLogin
                ? !this.props.policyLoaded
                : !this.props.eventLoaded)) && (
              <div className="vertical-center">
                <Loader />
              </div>
            )}
          {!this.props.init.path &&
            !this.props.isValidUrl &&
            !this.props.loading && <Screen404 />}
          {!this.props.loading && (showLogin ? <Splash /> : <Main />)}
          <Notifications />
          {this.props.shouldPromptLogout && <Logoutter />}
        </div>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  loading: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  policy: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  changeSection: PropTypes.func.isRequired,
  route: PropTypes.string,
  items: PropTypes.object.isRequired,
  popstate: PropTypes.bool.isRequired,
  doingAjax: PropTypes.bool.isRequired,
  isValidUrl: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  selectHotel: PropTypes.func.isRequired,
  showSpeaker: PropTypes.func.isRequired,
  showExhibitor: PropTypes.func.isRequired,
  createNewAbstract: PropTypes.func.isRequired,
  showAbstract: PropTypes.func.isRequired,
  showAbstractPreview: PropTypes.func.isRequired,
  showSponsor: PropTypes.func.isRequired,
  showLogin: PropTypes.func.isRequired,
  showRegister: PropTypes.func.isRequired,
  showVideo: PropTypes.func.isRequired,
  showSpeeches: PropTypes.func.isRequired,
  mobileToggled: PropTypes.bool.isRequired,
  eventLoaded: PropTypes.bool.isRequired,
  policyLoaded: PropTypes.bool.isRequired,
  event: PropTypes.object,
  init: PropTypes.object,
  noteLastProhibitedURL: PropTypes.func,
  shouldPromptLogout: PropTypes.bool,
  promptLogout: PropTypes.func,
};

App.defaultProps = {
  user: { data: {} },
};
const mapStateToProps = (state) => {
  return {
    loading: state.api.init.loading,
    eventLoaded: state.api.event.ready,
    policyLoaded: state.api.policy.ready,
    doingAjax: state.api.doingAjax,
    isValidUrl: state.api.init.isValidUrl,
    loggedIn: state.api.user.loggedIn,
    user: state.api.user,
    event: state.api.event,
    policy: state.api.policy,
    route: state.menu.items[state.menu.active].route,
    section: state.menu.active,
    items: state.menu.items,
    popstate: state.menu.popstate,
    mobileToggled: state.menu.mobileToggled,
    shouldPromptLogout: state.menu.promptLogout,
    init: state.api.init,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeSection: (section, popstate, pageProps) =>
    dispatch(changeSection(section, popstate, pageProps)),
  selectHotel: (hotelId) => dispatch(selectHotel(hotelId)),
  noteLastProhibitedURL: (url) => dispatch(noteLastProhibitedURL(url)),
  showSpeaker: (speakerId) => dispatch(showSpeaker(speakerId)),
  showSponsor: (speakerId) => dispatch(showSponsor(speakerId)),
  showExhibitor: (exhibitorId) => dispatch(showExhibitor(exhibitorId)),
  showRegister: () => dispatch(showRegister()),
  showLogin: () => dispatch(showLogin()),
  showVideo: (sessionId, speechId) => dispatch(showVideo(sessionId, speechId)),
  showSpeeches: (sessionId) => dispatch(showSpeeches(sessionId)),
  createNewAbstract: () => dispatch(createNewAbstract()),
  showAbstract: (abstractId) => dispatch(showAbstract(abstractId)),
  showAbstractPreview: (abstractId) =>
    dispatch(showAbstractPreview(abstractId)),
  promptLogout: () => dispatch(promptLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
