import {
  SETUP_DATA,
  CHANGE_SCREEN,
  SET_COOLDOWN,
  REDUCE_COOLDOWN,
  CLEAR_COOLDOWN,
  TOGGLE_PASSWORD_VISIBILITY,
  RECEIVED_USER_GET,
  USER_LOGGED_IN,
  USER_NOT_LOGGED_IN,
  LOGIN_FAILED,
  SHOW_SPEECHES,
  CHANGE_SECTION,
  BUILD_SESSIONS_CALENDAR,
  SHOW_SPEAKER,
  REGISTRATION_FAILED,
  SHOW_HOTEL,
  SHOW_ABSTRACT,
  SHOW_ABSTRACTS,
  PREVIEW_ABSTRACT,
  SHOW_VIDEO,
  SHOW_PRODUCT,
  SEARCH_VIDEO,
  SHOW_PAYMENT,
  RECEIVED_USER_UPDATE,
  SET_COUNTER,
  HAS_UNSAVED_CHANGES,
  HAS_REQUESTED_CHANGE_SECTION,
  SHOW_SPONSOR,
  SHOW_EXHIBITION,
  RECEIVED_PRODUCTS_LIST,
  RECEIVED_EXHIBITION_LIST,
  RECEIVED_EXHIBITION_GET,
  RECEIVED_POLICIES_GET,
  SHOW_MY_ABSTRACTS,
  SHOW_PUBLISHED_ABSTRACTS,
  SET_CAPTCHA_TOKEN,
  RESET_FAIL_COUNTER,
  CAPTCHA_FAIL_COUNTER,
  RECEIVED_BADGE_GET_ERROR,
  NOTE_LAST_PROHIBITED_URL,
} from '@actions';
import { setPrimaryColor } from '@config/colorPalette';
import { initialState } from './state';
import {
  RECEIVED_EVENT_GET,
  RECEIVED_SESSIONS_GET,
  RECEIVED_SESSIONS_GETBYID,
  RECEIVED_USER_ADDTOAGENDA,
  RECEIVED_SPEAKERS_GET,
  RECEIVED_SPEAKERS_GETSINGLE,
  RECEIVED_HOTELS_LIST,
  RECEIVED_PERSONS_LIST,
  RECEIVED_SCHEMA_GET,
  RECEIVED_ABSTRACTS_LIST,
  RECEIVED_ABSTRACTS_MYLIST,
  RECEIVED_ABSTRACTS_GET,
  RECEIVED_ABSTRACTS_CREATE,
  REQUEST_ABSTRACTS_GET,
  REQUEST_BADGE_GET,
  RECEIVED_BADGE_GET,
  REQUEST_SESSIONS_GET,
  FAILED_REQUEST,
  RECEIVED_USER_NOTIFICATIONS,
  RECEIVED_ROOMS_LIST,
  REQUEST_ROOMS_LIST,
  RECEIVED_VIDEOS_GET,
} from '@actions/ajax';
import {
  RECEIVED_CERTIFICATE_GET_ERROR,
  SET_PRODUCTS_TAB_INDEX,
} from '../actions';
import {
  RECEIVED_BADGEAPPLEWALLET_GET,
  RECEIVED_GOOGLEWALLETPASS_GET,
  REQUEST_BADGEAPPLEWALLET_GET,
  REQUEST_GOOGLEWALLETPASS_GET,
  REQUEST_HOTELS_CREATERESERVATION,
} from '../actions/ajax';

const PRIMARY_COLOR = '#348cfe';

// TODO: Remove this abomination and/or attrocity when window.colorPalette listens to global state isntead
//? Note for future devs: sorry not sorry
window.colorPalette = setPrimaryColor(PRIMARY_COLOR);

const api = (state = initialState, action) => {
  switch (action.type) {
    case NOTE_LAST_PROHIBITED_URL: {
      return Object.assign({}, state, {
        lastProhibitedUrl: action.url,
      });
    }

    case SET_COOLDOWN: {
      const newSectionState = {
        ...state.requestThrottle[action.flow],
        timeout: action.timeout,
        cooldown: action.cooldown,
        targetCooldown: action.targetCooldown,
        lastTimestamp: action.lastTimestamp,
        iteration: state.requestThrottle[action.flow].iteration + 1,
      };

      return Object.assign({}, state, {
        requestThrottle: {
          ...state.requestThrottle,
          [action.flow]: newSectionState,
        },
      });
    }

    case CLEAR_COOLDOWN: {
      const newSectionState = {
        ...state.requestThrottle[action.flow],
        timeout: action.timeout,
      };

      return Object.assign({}, state, {
        requestThrottle: {
          ...state.requestThrottle,
          [action.flow]: newSectionState,
        },
      });
    }

    case REDUCE_COOLDOWN: {
      const newSectionState = {
        ...state.requestThrottle[action.flow],
        cooldown: action.cooldown,
      };
      return Object.assign({}, state, {
        requestThrottle: {
          ...state.requestThrottle,
          [action.flow]: newSectionState,
        },
      });
    }

    case SET_CAPTCHA_TOKEN: {
      const newRecaptchaState = state.recaptcha;
      newRecaptchaState[action.componentToVerify] = {
        token: action.token,
        valid: action.tokenValidity,
        failedAttempts:
          state.recaptcha[action.componentToVerify].failedAttempts ?? 0,
      };
      return Object.assign({}, state, {
        recaptcha: Object.assign({}, state.recaptcha, newRecaptchaState),
      });
    }

    case CAPTCHA_FAIL_COUNTER: {
      const newRecaptchaState = state.recaptcha;
      newRecaptchaState[action.recaptchaSection] = {
        token: state.recaptcha[action.recaptchaSection].token,
        valid: state.recaptcha[action.recaptchaSection].valid,
        failedAttempts:
          (state.recaptcha[action.recaptchaSection].failedAttempts ?? 0) + 1,
      };

      return Object.assign({}, state, {
        recaptcha: Object.assign({}, state.recaptcha, newRecaptchaState),
      });
    }

    case RESET_FAIL_COUNTER: {
      const newRecaptchaState = state.recaptcha;
      newRecaptchaState[action.recaptchaSection] = {
        token: null,
        valid: false,
        failedAttempts: 0,
      };

      return Object.assign({}, state, {
        recaptcha: Object.assign({}, state.recaptcha, newRecaptchaState),
      });
    }

    case RECEIVED_USER_NOTIFICATIONS: {
      return Object.assign({}, state, {
        userNotifications: Object.assign({}, state.userNotifications, {
          data: action.response.data,
        }),
      });
    }

    case RECEIVED_ABSTRACTS_CREATE: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          showingAbstract: Object.assign({}, state.abstracts.showingAbstract, {
            active: true,
            fetching: true,
            ready: true,
            data: action.response.data,
            abstractId: action.response.data.id,
          }),
        }),
      });
    }

    case REQUEST_ABSTRACTS_GET: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          fetching: true,
          showingAbstract: Object.assign({}, state.abstracts.showingAbstract),
          showingAbstractPreview: Object.assign(
            {},
            state.abstracts.showingAbstractPreview
          ),
        }),
      });
    }

    case RECEIVED_ABSTRACTS_GET: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          fetching: false,
          showingAbstract: Object.assign({}, state.abstracts.showingAbstract, {
            data: action.response.data,
          }),
          showingAbstractPreview: Object.assign(
            {},
            state.abstracts.showingAbstractPreview,
            {
              data: action.response.data,
            }
          ),
        }),
      });
    }
    case REQUEST_GOOGLEWALLETPASS_GET:
    case REQUEST_BADGEAPPLEWALLET_GET:
    case REQUEST_BADGE_GET: {
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          badgeLoading: true,
        }),
      });
    }

    case RECEIVED_GOOGLEWALLETPASS_GET:
    case RECEIVED_BADGEAPPLEWALLET_GET:
    case RECEIVED_BADGE_GET:
    case RECEIVED_BADGE_GET_ERROR: {
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          badgeLoading: false,
        }),
      });
    }
    case RECEIVED_CERTIFICATE_GET_ERROR: {
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          certificateLoading: false,
        }),
      });
    }

    case RECEIVED_VIDEOS_GET: {
      return Object.assign({}, state, {
        videos: Object.assign({}, state.videos, {
          videos: action.response.data,
          meta: action.response.meta,
          fetching: false,
        }),
      });
    }

    case SHOW_ABSTRACT: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          showingAbstract: Object.assign({}, state.abstracts.showingAbstract, {
            active: true,
            abstractId: action.abstractId,
          }),
        }),
      });
    }

    case SHOW_ABSTRACTS: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          showingAbstract: Object.assign({}, state.abstracts.showingAbstract, {
            active: true,
            abstractId: null,
            data: null,
          }),
          showingAbstractPreview: Object.assign(
            {},
            state.abstracts.showingAbstractPreview,
            {
              active: false,
              data: null,
              abstractId: null,
            }
          ),
        }),
      });
    }

    case PREVIEW_ABSTRACT: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          showingAbstractPreview: Object.assign(
            {},
            state.abstracts.showingAbstractPreview,
            {
              active: true,
              abstractId: action.abstractId,
            }
          ),
        }),
      });
    }

    case SHOW_VIDEO: {
      return Object.assign({}, state, {
        videos: Object.assign({}, state.videos, {
          showingVideo: Object.assign({}, state.hotels.showingVideo, {
            active: true,
            sessionId: action.sessionId,
            speechId: action.speechId,
          }),
        }),
      });
    }

    case SEARCH_VIDEO: {
      return Object.assign({}, state, {
        videos: Object.assign({}, state.videos, {
          data: {
            search: action.search,
            speakerIds: action.speakerIds,
            roomIds: action.roomIds,
          },
        }),
      });
    }

    case SHOW_PAYMENT: {
      return Object.assign({}, state, {
        payments: Object.assign({}, state.payments, {
          showingPayment: {
            active: true,
            products: action.products,
            info: action.info,
          },
        }),
      });
    }
    case REQUEST_HOTELS_CREATERESERVATION: {
      return Object.assign({}, state, {
        payments: Object.assign({}, state.payments, {
          creatingPaymentRecord: true,
        }),
      });
    }

    case SET_COUNTER: {
      return Object.assign({}, state, {
        counter: action.counter,
      });
    }
    case HAS_UNSAVED_CHANGES: {
      return Object.assign({}, state, {
        hasUnsavedChanges: action.status,
      });
    }
    case HAS_REQUESTED_CHANGE_SECTION: {
      return Object.assign({}, state, {
        hasRequestedChangeSection: action.status,
      });
    }
    case RECEIVED_ABSTRACTS_LIST: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          data: action.response.data,
          meta: action.response.meta,
          ready: true,
          fetching: false,
        }),
      });
    }
    case RECEIVED_ABSTRACTS_MYLIST: {
      return Object.assign({}, state, {
        myAbstracts: Object.assign({}, state.myAbstracts, {
          data: action.response.data,
          meta: action.response.meta,
          ready: true,
          fetching: false,
        }),
      });
    }
    case RECEIVED_SCHEMA_GET: {
      return Object.assign({}, state, {
        schema: Object.assign({}, state.schema, {
          data: action.response.data,
          meta: action.response.meta,
          ready: true,
          fetching: false,
        }),
      });
    }

    case RECEIVED_PERSONS_LIST: {
      return Object.assign({}, state, {
        persons: Object.assign({}, state.persons, {
          data: action.response.data,
          meta: action.response.meta,
          ready: true,
          fetching: false,
        }),
      });
    }

    case SHOW_HOTEL: {
      return Object.assign({}, state, {
        hotels: Object.assign({}, state.hotels, {
          showingHotel: Object.assign({}, state.hotels.showingHotel, {
            active: true,
            hotelId: action.hotelId,
          }),
        }),
      });
    }
    case SET_PRODUCTS_TAB_INDEX: {
      return Object.assign({}, state, {
        products: Object.assign({}, state.products, {
          productsTabIndex: action.index,
        }),
      });
    }

    case SHOW_PRODUCT: {
      return Object.assign({}, state, {
        products: Object.assign({}, state.products, {
          showingProduct: Object.assign({}, state.hotels.showingProduct, {
            active: true,
            productId: action.productId,
          }),
        }),
      });
    }

    case RECEIVED_HOTELS_LIST: {
      return Object.assign({}, state, {
        hotels: Object.assign({}, state.hotels, {
          data: action.response.data,
          meta: action.response.meta,
          ready: true,
          fetching: false,
        }),
      });
    }

    case RECEIVED_SPEAKERS_GETSINGLE: {
      return Object.assign({}, state, {
        speakers: Object.assign({}, state.speakers, {
          showingSpeaker: Object.assign({}, state.speakers.showingSpeaker, {
            data: action.response.data,
            ready: true,
          }),
          fetching: false,
        }),
      });
    }

    case SHOW_SPEAKER: {
      return Object.assign({}, state, {
        speakers: Object.assign({}, state.speakers, {
          showingSpeaker: Object.assign({}, state.speakers.showingSpeaker, {
            active: true,
            speakerId: action.speakerId,
          }),
        }),
        sessions: Object.assign({}, state.sessions, {
          showingSpeeches: Object.assign(
            {},
            initialState.sessions.showingSpeeches
          ),
        }),
      });
    }

    case SHOW_MY_ABSTRACTS: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          abstractTabIndex: 1,
          showingAbstract: Object.assign({}, state.abstracts.showingAbstract, {
            active: null,
            abstractId: null,
            data: null,
          }),
        }),
      });
    }

    case SHOW_PUBLISHED_ABSTRACTS: {
      return Object.assign({}, state, {
        abstracts: Object.assign({}, state.abstracts, {
          abstractTabIndex: 0,
        }),
      });
    }

    case RECEIVED_SPEAKERS_GET: {
      return Object.assign({}, state, {
        speakers: Object.assign({}, state.speakers, {
          data: action.response.data,
          meta: action.response.meta,
          ready: true,
          fetching: false,
        }),
      });
    }
    case RECEIVED_ROOMS_LIST: {
      return Object.assign({}, state, {
        rooms: Object.assign({}, state.rooms, {
          data: action.response.data,
          fetching: false,
        }),
      });
    }
    case REQUEST_ROOMS_LIST: {
      return Object.assign({}, state, {
        rooms: Object.assign({}, state.rooms, {
          fetching: true,
        }),
      });
    }
    case BUILD_SESSIONS_CALENDAR: {
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, {
          calendar: action.calendar,
          calendarReady: true,
        }),
      });
    }

    case RECEIVED_USER_ADDTOAGENDA: {
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          data: Object.assign({}, state.user.data, {
            info: action.response.data.info,
          }),
        }),
      });
    }
    case RECEIVED_USER_UPDATE: {
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          data: Object.assign({}, state.user.data, {
            info: action.response.data.info,
            subscription: action.response.data.subscription,
          }),
        }),
      });
    }

    case CHANGE_SECTION: {
      return Object.assign({}, state, {
        payments: Object.assign({}, state.payments, {
          showingPayment: Object.assign({}, state.showingPayment, {
            active: false,
          }),
        }),
        videos: Object.assign({}, state.videos, {
          showingVideo: Object.assign({}, state.showingVideo, {
            active: false,
            sessionId: null,
            speechId: null,
          }),
        }),
        sessions: Object.assign({}, state.sessions, {
          showingSpeeches: Object.assign({}, state.showingSpeeches, {
            active: false,
            sessionId: null,
          }),
        }),
        speakers: Object.assign({}, state.speakers, {
          showingSpeaker: Object.assign({}, state.showingSpeaker, {
            active: false,
            speakerId: null,
            data: {},
            ready: false,
          }),
        }),
        hotels: Object.assign({}, state.hotels, {
          showingHotel: Object.assign({}, state.showingHotel, {
            active: false,
            sessionId: null,
          }),
        }),
        abstracts: Object.assign({}, state.abstracts, {
          showingAbstract: Object.assign({}, state.showingAbstract, {
            active: false,
            abstractId: null,
          }),
          showingAbstractPreview: Object.assign(
            {},
            state.showingAbstractPreview,
            {
              active: false,
              abstractId: null,
            }
          ),
        }),
        sponsors: Object.assign({}, state.sponsors, {
          showingSponsor: Object.assign({}, state.showingSponsor, {
            active: false,
          }),
        }),
        ebag: Object.assign({}, state.ebag, {
          showingEbag: Object.assign({}, state.showingEbag, {
            active: false,
          }),
        }),
        login: Object.assign({}, state.login, {
          showingLogin: Object.assign({}, state.showingLogin, {
            active: false,
          }),
        }),
        exhibition: Object.assign({}, state.exhibition, {
          showingExhibitor: Object.assign({}, state.showingExhibitor, {
            active: false,
          }),
        }),
        products: Object.assign({}, state.products, {
          showingProduct: Object.assign({}, state.showingProduct, {
            active: false,
          }),
        }),
      });
    }

    case SHOW_SPEECHES: {
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, {
          showingSpeeches: Object.assign({}, state.sessions.showingSpeeches, {
            active: true,
            sessionId: action.sessionId,
          }),
        }),
        speakers: Object.assign({}, state.speakers, {
          showingSpeaker: Object.assign(
            {},
            initialState.speakers.showingSpeaker
          ),
        }),
      });
    }
    case SHOW_SPONSOR: {
      return Object.assign({}, state, {
        sponsors: Object.assign({}, state.sponsors, {
          showingSponsor: Object.assign({}, state.sponsors.showingSponsor, {
            active: true,
          }),
        }),
      });
    }

    case SHOW_EXHIBITION: {
      return Object.assign({}, state, {
        exhibition: Object.assign({}, state.exhibition, {
          showingExhibitor: Object.assign(
            {},
            state.exhibition.showingExhibitor,
            {
              active: true,
              exhibitorId: action.exhibitorId,
              ready: false,
            }
          ),
        }),
      });
    }
    case RECEIVED_EXHIBITION_LIST: {
      return Object.assign({}, state, {
        exhibition: Object.assign({}, state.exhibition, {
          data: action.response.data,
          ready: true,
        }),
      });
    }
    case RECEIVED_EXHIBITION_GET: {
      return Object.assign({}, state, {
        exhibition: Object.assign({}, state.exhibition, {
          showingExhibitor: Object.assign(
            {},
            state.exhibition.showingExhibitor,
            {
              data: action.response.data,
              ready: true,
            }
          ),
          fetching: false,
        }),
      });
    }
    case RECEIVED_PRODUCTS_LIST: {
      return Object.assign({}, state, {
        products: Object.assign({}, state.products, {
          data: action.response.data,
          ready: true,
        }),
      });
    }
    case RECEIVED_POLICIES_GET: {
      return Object.assign({}, state, {
        products: Object.assign({}, state.products, {
          data: action.response.data.products,
          ready: true,
        }),
      });
    }

    case REQUEST_SESSIONS_GET: {
      return Object.assign(
        {},
        state,
        {
          sessions: Object.assign({}, state.sessions, {
            ready: false,
            fetching: true,
          }),
        },
        {
          videos: Object.assign({}, state.videos, {
            ready: false,
            fetching: true,
          }),
        }
      );
    }
    case RECEIVED_SESSIONS_GETBYID:
      return Object.assign(
        {},
        state,
        {
          innerSession: Object.assign({}, state.innerSession, {
            data: action.response.data,
            meta: action.response.meta,
            ready: true,
            fetching: false,
          }),
        },
        {
          videos: Object.assign({}, state.videos, {
            ready: true,
            fetching: false,
          }),
        }
      );
    case RECEIVED_SESSIONS_GET: {
      return Object.assign(
        {},
        state,
        {
          sessions: Object.assign({}, state.sessions, {
            data: action.response.data,
            meta: action.response.meta,
            ready: true,
            fetching: false,
          }),
        },
        {
          videos: Object.assign({}, state.videos, {
            ready: true,
            fetching: false,
          }),
        }
      );
    }

    case RECEIVED_EVENT_GET: {
      const colorPalette = setPrimaryColor(
        action.response.data.clientPanelSettings.general.colors.primary
      );
      // TODO: Go to application and cleanup colorPalette calls in app to listen to the state object rather than the window.
      window.colorPalette = colorPalette;
      return Object.assign({}, state, {
        event: Object.assign({}, state.event, {
          data: action.response.data,
          meta: action.response.meta,
          ready: true,
          fetching: false,
        }),
        colorPalette,
      });
    }

    case LOGIN_FAILED: {
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          errorMessage: action.message,
        }),
      });
    }

    case REGISTRATION_FAILED: {
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          errorMessage: action.message,
        }),
      });
    }

    case USER_NOT_LOGGED_IN: {
      return Object.assign({}, state, {
        init: Object.assign({}, state.init, {
          loading: false,
        }),
      });
    }

    case USER_LOGGED_IN: {
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          ready: true,
          data: action.user,
          loggedIn: true,
          pseudo: action.pseudo,
        }),
        init: Object.assign({}, state.init, {
          loading: false,
        }),
      });
    }

    case RECEIVED_USER_GET: {
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          ready: true,
          data: action.response.data,
          loggedIn: true,
        }),
        init: Object.assign({}, state.init, {
          loading: false,
        }),
        payments: Object.assign({}, state.payments, {
          creatingPaymentRecord: false,
        }),
      });
    }
    case TOGGLE_PASSWORD_VISIBILITY: {
      return Object.assign({}, state, {
        policy: Object.assign({}, state.policy, {
          visibility: Object.assign({}, state.policy.visibility, {
            [action.key]: !state.policy.visibility[action.key],
          }),
        }),
      });
    }

    case CHANGE_SCREEN: {
      return Object.assign({}, state, {
        policy: Object.assign({}, state.policy, {
          screen: action.screen,
          email: action.email,
          password: action.password,
        }),
        user: Object.assign({}, state.user, {
          errorMessage: '',
        }),
      });
    }

    case SETUP_DATA: {
      const colorPalette = setPrimaryColor(
        action.policy.data.clientPanelSettings.general.colors.primary
      );
      // TODO: Go to application and cleanup colorPalette calls in app to listen to the state object rather than the window.
      window.colorPalette = colorPalette;
      return Object.assign({}, state, {
        init: Object.assign({}, state.init, {
          url: action.url,
          path: action.path,
        }),
        language: Object.assign({}, state.lang, {
          selected: action.policy.data.language || 'en',
        }),
        policy: Object.assign({}, state.policy, {
          data: action.policy.data,
          meta: action.policy.meta,
          ready: true,
          resetPasswordAvailable: action.resetPasswordAvailable,
        }),
        colorPalette,
      });
    }

    case FAILED_REQUEST: {
      return Object.assign({}, state, {
        init: Object.assign({}, state.init, {
          isValidUrl: false,
          loading: false,
        }),
        doingAjax: false,
        [action.endpoint]: Object.assign({}, state[action.endpoint], {
          fetching: false,
        }),
      });
    }

    default: {
      if (action.type.substring(0, 7) === 'REQUEST') {
        // DOING AJAX

        if (!state.hasOwnProperty(action.endpoint)) {
          return state;
        }

        return Object.assign({}, state, {
          doingAjax: true,
          [action.endpoint]: Object.assign({}, state[action.endpoint], {
            fetching: true,
            meta: {},
          }),
        });
      }

      return state;
    }
  }
};

export default api;
