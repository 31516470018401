import React from 'react';
import Barcode from 'react-barcode';
// Dynamic badge content rendering based on the settings
export const renderBadgeDynamicContent = (settings, paperSizesPX) => {
  const isLandscape = settings.orientation === 'landscape';
  const paperSize = paperSizesPX[settings.paperSize] || paperSizesPX.A6; // Default to A4 if no match

  const pageWidth = isLandscape ? paperSize.height : paperSize.width;
  const pageHeight = isLandscape ? paperSize.width : paperSize.height;

  if (!settings) {
    return <div>No settings available</div>;
  }
  return (
    <div
      style={{
        position: 'relative',
        width: pageWidth,
        height: pageHeight,
        fontFamily: settings.fontFamily,
        color: settings.fontColor,
      }}>
      {/* Banner */}
      {settings.useBanner && settings.banner?.url && (
        <img
          className="banner"
          src={`data:image/${settings.imageType || 'jpeg'};base64,${
            settings.image_base64
          }`} // Dynamically use the image type
          alt="Banner"
          style={{
            width: '100%', // Use the full width of the page
            height: '100%',
            objectFit: 'cover',
          }}
        />
      )}

      {/* Name */}
      {settings.showName && (
        <div
          className="name"
          style={{
            position: 'absolute',
            top: settings.nameStyle.top,
            left: settings.centerName ? 0 : settings.nameStyle.left, // Force left:0 if centered
            width: settings.centerName ? '100%' : 'auto',
            fontSize: settings.nameStyle.fontSize,
            color: settings.fontColor,
            textAlign: settings.centerName ? 'center' : 'left',
            display: 'flex',
            flexDirection: settings.sameLine ? 'row' : 'column',
            justifyContent: settings.centerName ? 'center' : 'flex-start',
            alignItems: settings.centerName ? 'center' : 'flex-start',
          }}>
          {createDynamicName(settings)}
        </div>
      )}

      {/* Barcode */}
      {settings.showBarcode && (
        <div
          className="barcode-container barcode"
          style={{
            position: 'absolute',
            top: settings.barcodeStyle.top,
            left: settings.barcodeStyle.left,
            height: settings.barcodeHeight * settings.scale,
            textAlign: settings.centerBarcode ? 'center' : 'left',
            display: 'flex',
            justifyContent: settings.centerBarcode ? 'center' : 'flex-start',
            width: settings.centerBarcode ? '100%' : 'auto',
          }}>
          <Barcode
            value={String(settings.user.id)} // Barcode value
            width={2} // Width of the barcode
            height={settings.barcodeHeight} // Height of the barcode
            format="CODE128" // Barcode format
            displayValue={true} // Display value below the barcode
            font="monospace" // Font for the text
            textAlign="center" // Align text below the barcode
            textPosition="bottom" // Text position
            fontSize={20} // Font size for the text
            lineColor="#000000" // Line color for the barcode
            background="#ffffff" // Background color
          />
        </div>
      )}

      {/* Dynamic fields */}
      {settings.fieldConfig_1.showField && (
        <div
          className="field-1"
          style={{
            position: 'absolute',
            top: settings.fieldConfig_1.top,
            left: settings.fieldConfig_1.left,
            fontSize: settings.fieldConfig_1.fontSize,
            textAlign: settings.fieldConfig_1.centerField ? 'center' : 'left',
            display: 'flex',
            justifyContent: settings.fieldConfig_1.centerField
              ? 'center'
              : 'flex-start',
            width: settings.fieldConfig_1.centerField ? '100%' : 'auto',
          }}>
          {createDynamicField(1, settings)}
        </div>
      )}

      {settings.fieldConfig_2.showField && (
        <div
          className="field-2"
          style={{
            position: 'absolute',
            top: settings.fieldConfig_2.top,
            left: settings.fieldConfig_2.left,
            fontSize: settings.fieldConfig_2.fontSize,
            textAlign: settings.fieldConfig_2.centerField ? 'center' : 'left',
            display: 'flex',
            justifyContent: settings.fieldConfig_2.centerField
              ? 'center'
              : 'flex-start',
            width: settings.fieldConfig_2.centerField ? '100%' : 'auto',
          }}>
          {createDynamicField(2, settings)}
        </div>
      )}
    </div>
  );
};

export const createDynamicName = (settings) => {
  const { user } = settings;
  if (settings.sameLine) {
    return settings.nameOrder === 'first'
      ? `${user.info.firstName} ${user.info.lastName}`
      : `${user.info.lastName} ${user.info.firstName}`;
  } else {
    return settings.nameOrder === 'first' ? (
      <>
        <div>{user.info.firstName}</div>
        <div>{user.info.lastName}</div>
      </>
    ) : (
      <>
        <div>{user.info.lastName}</div>
        <div>{user.info.firstName}</div>
      </>
    );
  }
};

// Helper function to create dynamic field values
const createDynamicField = (fieldNum, settings) => {
  const user = settings.user;
  const field = settings[`fieldConfig_${fieldNum}`];
  const fieldKey = field.selectedField;
  const value = user.info[fieldKey];

  if (field.fieldType === 'yes_no') {
    return value === 1 ? fieldKey : '';
  }

  if (field.fieldType === 'textarea') {
    return value ? value.substring(0, 32) : '';
  }

  return value;
};
