import React from 'react';
import { createDynamicName } from './renderBadge';

export const renderCertificateDynamicContent = (settings, paperSizesPX) => {
  // Determine paper dimensions based on orientation and paper size.
  const isLandscape = settings.orientation === 'landscape';
  const paperSize = paperSizesPX[settings.paperSize] || paperSizesPX.A4; // Default to A4 if not defined

  const pageWidth = isLandscape ? paperSize.height : paperSize.width;
  const pageHeight = isLandscape ? paperSize.width : paperSize.height;

  if (!settings) {
    return <div>No settings available</div>;
  }

  return (
    <div
      style={{
        position: 'relative',
        width: pageWidth,
        height: pageHeight,
        fontFamily: settings.fontFamily,
        color: settings.fontColor,
      }}>
      {/* Banner as a background image */}
      {settings.image_base64 && (
        <img
          className="banner"
          src={`data:image/${settings.imageType || 'jpeg'};base64,${
            settings.image_base64
          }`}
          alt="Banner"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      )}

      {/* Certificate ID */}
      {settings.showId && (
        <div
          className="id"
          style={{
            position: 'absolute',
            top: settings.idStyle.top,
            left: settings.idStyle.left,
            fontSize: settings.idStyle.fontSize,
            color: settings.fontColor,
            textAlign: settings.centeredId ? 'center' : 'left',
            width: settings.centeredId ? '100%' : 'auto',
          }}>
          {settings.user.id}
        </div>
      )}

      {/* Certificate Name */}
      {settings.showName && (
        <div
          className="name"
          style={{
            position: 'absolute',
            top: settings.nameStyle.top,
            // When centered, override left to 0 so that textAlign and justifyContent can work correctly.
            left: settings.centeredName ? 0 : settings.nameStyle.left,
            fontSize: settings.nameStyle.fontSize,
            color: settings.fontColor,
            textAlign: settings.centeredName ? 'center' : 'left',
            width: settings.centeredName ? '100%' : 'auto',
            display: 'flex',
            flexDirection: settings.sameLine ? 'row' : 'column',
            // Center both horizontally and vertically when center is enabled.
            justifyContent: settings.centeredName ? 'center' : 'flex-start',
            alignItems: settings.centeredName ? 'center' : 'flex-start',
          }}>
          {createDynamicName(settings)}
        </div>
      )}

      {/* Certificate Text */}
      {settings.showCertText && (
        <div
          className="cert-text"
          style={{
            position: 'absolute',
            top: settings.certStyle.top,
            left: settings.certStyle.left,
            fontSize: settings.certStyle.fontSize,
            color: settings.fontColor,
            textAlign: settings.centeredPoints ? 'center' : 'left',
            width: settings.centeredPoints ? '100%' : 'auto',
          }}>
          {returnCertText(settings)}
        </div>
      )}
    </div>
  );
};

const returnCertText = (settings) => {
  // Early exit if certificate text should not be shown.
  if (!settings.showCertText) return '';
  // Optionally, check for user group membership, points etc.
  const userPoints = Number(settings.user.points);
  if (userPoints <= 0) return '';

  // Replace a placeholder (e.g., %points%) with userPoints or an override.
  if (settings.certText.match(/%.*%/)) {
    const pointsText = settings.overridePoints
      ? settings.certPoints
      : userPoints;
    return settings.certText.replace(/%.*%/, String(pointsText));
  }
  return settings.certText;
};
