import configuration from '@config/conf';
import { isArray, isObject, isFileArray } from './index';

const buildParams = (parameters, insecure, endpoint) => {
  delete parameters.url;

  const headers = new Headers();
  headers.append('Access-Control-Request-Headers', `Location`);
  if (!insecure) {
    headers.append('Authorization', `Bearer ${parameters.token}`);
  }

  if (endpoint.url.includes('request-reset-password')) {
    headers.append('Existingheader', `i_indeed_exist`);
  }

  if (!parameters.hasOwnProperty('method')) parameters.method = 'GET';

  if (parameters.method !== 'GET') {
    if (!parameters.hasOwnProperty('contentType')) {
      parameters.contentType = 'application/json';
      headers.append('Content-Type', parameters.contentType);
    }

    if (parameters.contentType === 'application/json') {
      if (
        parameters.hasOwnProperty('body') &&
        Object.keys(parameters.body).length
      ) {
        parameters.body = JSON.stringify(parameters.body);
      }
    } else if (parameters.contentType === 'multipart/form-data') {
      const formData = new FormData();

      for (const name in parameters.body) {
        const value = parameters.body[name];

        if (isArray(parameters.body[name])) {
          if (isFileArray(parameters.body[name])) {
            for (const v of parameters.body[name]) {
              formData.append(`${name}`, v);
            }
          } else {
            if (parameters.body[name].length === 1) {
              formData.append(`${name}`, JSON.stringify(parameters.body[name]));
            } else {
              for (const v of parameters.body[name]) {
                formData.append(`${name}`, isObject(v) ? JSON.stringify(v) : v);
              }
            }
          }
        } else if (isObject(parameters.body[name])) {
          formData.append(`${name}`, JSON.stringify(parameters.body[name]));
        } else {
          formData.append(name, value);
        }
      }

      parameters.body = formData;
    } else if (parameters.contentType === 'application/x-www-form-urlencoded') {
      const data = new URLSearchParams();
      for (const name in parameters.body) {
        data.append(name, parameters.body[name]);
      }
      parameters.body = data;
    }

    delete parameters.contentType;
  }

  parameters.headers = headers;

  return parameters;
};

export const sanitizeArgs = (args, tokenName) => {
  const response = {
    success: false,
    message: '',
    args: {},
    params: {},
    url: '',
    code: 0,
  };

  const token = args.hasOwnProperty('accessToken')
    ? args.accessToken
    : localStorage.getItem(tokenName);

  if (!args.insecure && !token) {
    response.message = 'Anauthorized access.';
    response.code = 403;
    return response;
  }

  if (!args.hasOwnProperty('endpoint') || !args.hasOwnProperty('action')) {
    response.message = 'Endpoint or action not defined.';
    return response;
  }

  if (!configuration.endpoints.hasOwnProperty(args.endpoint)) {
    response.message = 'Endpoint not listed on application.';
    return response;
  }

  if (!configuration.endpoints[args.endpoint].hasOwnProperty(args.action)) {
    response.message = "Endpoint's action not defined.";
    return response;
  }

  const endpoint = Object.assign(
    {},
    configuration.endpoints[args.endpoint][args.action]
  );
  if (!args.insecure) {
    endpoint.token = token;
  }

  if (args.hasOwnProperty('body')) {
    endpoint.body = args.body;
  }

  if (args.hasOwnProperty('contentType')) {
    endpoint.contentType = args.contentType;
  }

  if (args.hasOwnProperty('params')) {
    // the endpoint's url has dynamic
    // parameters. Let's populate them

    const populatedUrl = populateEndpoint(args.params, endpoint.url);
    response.url = `${configuration.url}/${populatedUrl}`;
  } else if (args.external) {
    response.url = `${endpoint.url}`;
    response.params.mode = 'no-cors';
  } else {
    response.url = `${configuration.url}/${endpoint.url}`;
  }

  if (args.hasOwnProperty('query') && endpoint.method === 'GET') {
    response.url += args.query;
  }

  response.success = true;
  response.params = buildParams(
    Object.assign({}, response.params, endpoint),
    args.insecure,
    endpoint
  );
  response.args = args;
  return response;
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON, status from the response
 */

export const parseJSON = (response, insecure) => {
  return new Promise((resolve, reject) => {
    if (response.status === 404 || (response.status === 403 && insecure)) {
      return reject(new Error(`Response status: ${response.status}`));
    }
    if (response.body !== null && typeof response.body === 'object') {
      if (blobHandler(response, resolve, reject)) {
        return;
      } else {
        response.json().then((json) => {
          json.status = response.status;
          response.ok
            ? resolve(json)
            : reject(Object.assign({}, json.meta, { status: response.status }));
        });
      }
    } else {
      resolve(response);
    }
  });
};

export const blobHandler = (response, resolve, reject) => {
  const fileName = parseFileSource(response);
  if (fileName) {
    response
      .blob()
      .then((blob, filename = fileName) => {
        fileDownload(blob, filename);
        resolve(response);
      })
      .catch((e) => {
        reject(
          new Error(`Response status: ${response.status}. Blob error : ${e}`)
        );
      });
    return true;
  } else {
    return false;
  }
};

export const parseFileSource = () => {
  // if (response.url.includes('/badge')) {
  //   return 'badge_PDF';
  // }
  return null;
};

export const fileDownload = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const populateEndpoint = (params, url) => {
  for (const [key, value] of Object.entries(params)) {
    url = url.replace('${' + `${key}` + '}', value);
  }

  return url;
};

export const callApi = (url, params, insecure = false, dataType = 'json') => {
  return new Promise((resolve, reject) => {
    return fetch(url, params)
      .then(async (response) => {
        if (response.status >= 400) {
          try {
            const res = await response.json();
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({
              cause: { status: response.status },
              ...res.meta,
            });
          } catch (error) {
            return Promise.reject(new Error(error), {
              cause: { status: response.status },
            });
          }
        }
        return dataType === 'buffer'
          ? response.arrayBuffer().then((buffer) => buffer)
          : parseJSON(response, insecure);
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        let message = '';
        if (err.errorMessage) {
          message += err.errorMessage;
        }
        if (err.description) {
          message += err.errorMessage ? ' - ' : '';
          message += err.description;
        }
        return reject(
          new Error(
            message || 'Something went wrong. Please try again later.',
            err
          )
        );
      });
  });
};
