import { connect } from 'react-redux';
import {
  changeScreen,
  logout,
  changeSection,
  getUser,
  updateInViewMenuItem,
} from '@actions';
import AppMobileTopbar from './AppMobileTopbar';

const mapStateToProps = (state) => {
  return {
    user: state.api.user,
    policy: state.api.policy,
    isToggled: state.menu.mobileToggled,
    language: state.api.language.selected,
    menu: state.menu.items,
    ready: state.menu.initialized,
    active: state.menu.active,
    event: state.api.event.data,
    currency: state.api.event.data.currencySymbol,
    itemsInView: state.menu.itemsInView,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUser()),
  logout: () => dispatch(logout(true)),
  changeSection: (section, popstate, pageProps) =>
    dispatch(changeSection(section, popstate, pageProps)),
  changeScreen: (section) => dispatch(changeScreen(section)),
  updateInViewMenuItem: (key, status) =>
    dispatch(updateInViewMenuItem(key, status)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AppMobileTopbar);
