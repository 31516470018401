import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AccountIcon } from '@dynamic_assets/accounticon.svg';
import {
  createToken,
  getAppleWalletBadge,
  getGoogleWalletBadge,
} from '@actions';
import Button from '@material-ui/core/Button';
import PasswordResetModal from './partials/PasswordResetModal';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import EditInfoModal from './partials/EditInfoModal';
import Barcode from 'react-barcode';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkIfConditionIsFulfilled } from '../../../../../helpers';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Typography } from '@material-ui/core';
import AppleWalletIcon from '@public/apple-wallet-condensed.png';
import GoogleWalletIcon from '@public/google-wallet-condensed.png';

const AccountInfo = (props) => {
  const [passwordResetIsOpen, setPasswordResetIsOpen] = React.useState(false);
  const [editInfoIsOpen, setEditInfoIsOpen] = React.useState(false);
  const printBadge = async () => {
    if (props.badgeLoading) {
      return;
    }

    // Fetch badge HTML content
    props.createToken('badge').then((response) => {
      // Open a new blank window
      window.open(
        `${window.location.origin}/${props.event.urlAlias}/print?t=${response.data.token}&print=true&type=badge`
      );
    });
  };
  const printAppleWalletBadge = () => {
    if (props.badgeLoading) {
      return;
    }
    props.getAppleWalletBadge(props.event.id).then((buffer) => {
      // Assuming your backend sends the file as a buffer response
      const blob = new Blob([buffer], {
        type: 'application/vnd.apple.pkpass',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `event_badge_${props.event.id}.pkpass`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url); // Clean up after download
    });
  };

  const printGoogleWalletBadge = () => {
    if (props.badgeLoading) {
      return;
    }
    props.getGoogleWalletBadge(props.event.id).then(({ url }) => {
      window.open(url, '_blank');
    });
  };

  const closePasswordReset = () => {
    setPasswordResetIsOpen(false);
  };

  const openPasswordReset = () => {
    setPasswordResetIsOpen(true);
  };

  const openEditInfo = () => {
    setEditInfoIsOpen(true);
  };

  const closeEditInfo = () => {
    setEditInfoIsOpen(false);
  };

  const parseFieldValue = (field) => {
    const value = props.user.info[field.key];
    if (field.key === 'password') {
      return (
        <div
          className="password-reset-prompt"
          onClick={() => openPasswordReset()}>
          {lang[props.language]['passwordRequest']}
        </div>
      );
    }
    if (value && value.length !== 0) {
      return field.type === 'yes_no'
        ? `${value === '1' ? 'Yes' : value === '0' ? 'No' : null}`
        : `${value}`;
    } else {
      return 'N/A';
    }
  };
  const userOwes =
    props.user.subscriptionDueAmount > 0 && props.user.info?.sponsored != 1;
  return (
    <div className="my-account-container">
      {passwordResetIsOpen && (
        <PasswordResetModal
          user={props.user}
          requestResetPassword={props.requestResetPassword}
          openModal={openPasswordReset}
          closeModal={closePasswordReset}
          modalIsOpen={passwordResetIsOpen}
        />
      )}
      {editInfoIsOpen && (
        <EditInfoModal
          user={props.user}
          openModal={openEditInfo}
          closeModal={closeEditInfo}
          modalIsOpen={editInfoIsOpen}
        />
      )}

      <div className="my-account-header">
        {lang[props.language]['myAccount']}
      </div>
      <div className="info-wrapper">
        <div className="profile-info">
          <div className="badge-wrapper">
            {/* todo: UPDATE SECTION WHEN PROFILE PICS ARE AVAILABLE */}
            <div className="user-account-no-avatar-wrapper">
              <div className="user-account-no-avatar">
                <AccountIcon color={window.colorPalette.primary[600]} />
              </div>
            </div>
            <div className="user-credentials">
              <div className="user-id">{`ID ${props.user.id}`}</div>
              <div className="user-name">
                {`${props.user.info.firstName || ''} ${
                  props.user.info.lastName || ''
                }`}
                <Tooltip
                  title={
                    <div
                      style={{ fontSize: '14px', lineHeight: '18px' }}
                      className="user-name-warning">
                      {lang[props.language]['userNameWarning']}
                    </div>
                  }>
                  <InfoOutlinedIcon />
                </Tooltip>
              </div>
              {props.user.info.email?.length > 0 && (
                <div className="user-email">{`${props.user.info.email}`}</div>
              )}
              {props.policy.type === 'private' && (
                <div className="user-barcode">
                  <Barcode
                    displayValue={false}
                    width="2"
                    height="45"
                    value={props.user.id}
                  />
                </div>
              )}
              {props.policy.type === 'private' && (
                <div
                  className={`print-badge-wrapper${
                    userOwes ? ' disabled' : ''
                  }`}>
                  <div>
                    <a href={'#'} onClick={printBadge} className="wallet-info">
                      {props.badgeLoading ? (
                        <CircularProgress
                          color="var(--primary-600)"
                          size={24}
                          className="submit-loader"
                        />
                      ) : (
                        <>
                          <PictureAsPdfIcon />
                          <Typography>PDF Badge</Typography>
                        </>
                      )}
                    </a>
                  </div>
                  <div className="wallet-flex">
                    <a
                      href={'#'}
                      onClick={printAppleWalletBadge}
                      className={props.badgeLoading ? 'wallet-info' : ''}>
                      {props.badgeLoading ? (
                        <CircularProgress
                          color="var(--primary-600)"
                          size={24}
                          className="submit-loader"
                        />
                      ) : (
                        <img
                          style={{ cursor: 'pointer' }}
                          height="44px"
                          src={AppleWalletIcon}
                        />
                      )}
                    </a>
                  </div>
                  <div className="wallet-flex">
                    <a
                      href={'#'}
                      onClick={printGoogleWalletBadge}
                      className={props.badgeLoading ? 'wallet-info' : ''}>
                      {props.badgeLoading ? (
                        <CircularProgress
                          color="var(--primary-600)"
                          size={24}
                          className="submit-loader"
                        />
                      ) : (
                        <img
                          style={{ cursor: 'pointer' }}
                          height="44px"
                          src={GoogleWalletIcon}
                        />
                      )}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <AccountStats {...props} /> */}
      </div>
      <div className="myaccount-divider" />
      <div className="info-fields-display">
        {props.policy?.fields?.map((field) => {
          if (field.type === 'section') return;
          if (checkIfConditionIsFulfilled(field, props.user.info) === false)
            return null;
          if (
            (props.policy.type === 'public' && field.key === 'password') ||
            (field.key === 'username' && props.policy.type === 'public')
          ) {
            return null;
          }
          return (
            <div
              className={`user-field user-field-${field.key}`}
              key={`user-field-${field.key}`}>
              <div className="field-title">{field.name}</div>
              <div className="field-value">{parseFieldValue(field)}</div>
            </div>
          );
        })}
      </div>
      <div className="process-info-wrapper">
        {props.policy.type === 'private' && (
          <>
            <div className="print-badge-button-mobile">
              <a
                href={'#'}
                onClick={printBadge}
                className="wallet-info wallet-flex">
                {props.badgeLoading ? (
                  <CircularProgress
                    color="var(--primary-600)"
                    size={24}
                    className="submit-loader"
                  />
                ) : (
                  <>
                    <PictureAsPdfIcon />
                    <Typography>Badge</Typography>
                  </>
                )}
              </a>
            </div>
            <div className="print-badge-button-mobile wallet-flex">
              <a
                href={'#'}
                onClick={printAppleWalletBadge}
                className="wallet-flex">
                {props.badgeLoading ? (
                  <CircularProgress
                    color="var(--primary-600)"
                    size={24}
                    className="submit-loader"
                  />
                ) : (
                  <>
                    <img
                      style={{ cursor: 'pointer' }}
                      height="44px"
                      src={AppleWalletIcon}
                    />
                  </>
                )}
              </a>
            </div>
            <div className="print-badge-button-mobile ">
              <a
                href={'#'}
                onClick={printGoogleWalletBadge}
                className="wallet-flex">
                {props.badgeLoading ? (
                  <CircularProgress
                    color="var(--primary-600)"
                    size={24}
                    className="submit-loader"
                  />
                ) : (
                  <img
                    style={{ cursor: 'pointer' }}
                    height="44px"
                    src={GoogleWalletIcon}
                  />
                )}
              </a>
            </div>
          </>
        )}

        <Button
          style={{ flexBasis: '100%' }}
          onClick={openEditInfo}
          className="process-info-button"
          variant="contained">
          {lang[props.language]['edit']}
        </Button>
      </div>
    </div>
  );
};

AccountInfo.propTypes = {
  policy: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  currency: PropTypes.string,
  updateUser: PropTypes.func,
  event: PropTypes.object,
  requestResetPassword: PropTypes.func,
  primaryColor: PropTypes.string,
  createToken: PropTypes.func,
  getAppleWalletBadge: PropTypes.func,
  getGoogleWalletBadge: PropTypes.func,
  getCert: PropTypes.func,
  badgeLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    language: state.api.language.selected,
    badgeLoading: state.api.user.badgeLoading,
    currency: state.api.event.data.currencySymbol,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createToken: (type) => dispatch(createToken(type)),
  getAppleWalletBadge: (eventId) => dispatch(getAppleWalletBadge(eventId)),
  getGoogleWalletBadge: (eventId) => dispatch(getGoogleWalletBadge(eventId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
